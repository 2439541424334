import { ReduxCalendarEvent } from './calendarData';
import { initLoadable, Loadable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssetType,
  IListEventsForPropertyHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

export type CalendarState = {
  events: Loadable<ReduxCalendarEvent[]>;
};

const initialState: CalendarState = {
  events: initLoadable<ReduxCalendarEvent[]>(),
};

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEventsAction: (
      state,
      action: PayloadAction<{ assetId: string; assetType: AssetType; body: IListEventsForPropertyHandlerRequest }>,
    ) => {
      state.events.loading = true;
      state.events.loaded = false;
    },
    getEventsSuccessAction: (state, action: PayloadAction<ReduxCalendarEvent[]>) => {
      state.events.loading = false;
      state.events.loaded = true;
      state.events.value = action.payload;
    },
    getEventsFailAction: (state) => {
      state.events.loading = false;
      state.events.loaded = false;
    },
  },
});

export const { getEventsAction, getEventsSuccessAction, getEventsFailAction } = calendarSlice.actions;

export const useCalendar = () => useSelector((state: RootState) => state.calendar);
