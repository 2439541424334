import { initInteractable, Interactable } from '@monkeyjump-labs/cam-fe-shared/dist/types/ApiData';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import {
  AssociationChildType,
  AssociationType,
  IUpdateDocumentMetadataHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

interface DocumentSlice {
  selectedDocument: Interactable<ReduxDocument>;
  importExport: Interactable<File>;
}

const initialState: DocumentSlice = {
  selectedDocument: initInteractable<ReduxDocument>(),
  importExport: initInteractable<File>(),
};
/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection JSUnusedLocalSymbols
export const documentSlice = createSlice({
  name: 'documents',
  initialState: initialState,
  reducers: {
    updateDocumentMetadataAction: (
      state,
      action: PayloadAction<{
        propertyId: string;
        associationType: AssociationType;
        associationId: string;
        childAssociationType?: AssociationChildType;
        childAssociationId?: string;
        documentId: string;
        body: IUpdateDocumentMetadataHandlerRequest;
      }>,
    ) => {
      state.selectedDocument.submitting = true;
      state.selectedDocument.submitted = false;
    },
    updateDocumentMetadataSuccessAction: (state) => {
      state.selectedDocument.submitted = true;
      state.selectedDocument.submitting = false;
    },
    updateDocumentMetadataFailAction: (state) => {
      state.selectedDocument.submitting = false;
      state.selectedDocument.submitted = false;
    },
    downloadTemplateAction: (state, action: PayloadAction<number>) => {
      state.importExport.submitting = true;
      state.importExport.submitted = false;
    },
    downloadTemplateSuccessAction: (state) => {
      state.importExport.submitting = false;
      state.importExport.submitted = true;
    },
    downloadTemplateFailAction: (state) => {
      state.importExport.submitting = false;
      state.importExport.submitted = false;
    },
    uploadBulkAssetsAction: (state, action: PayloadAction<{ file: File; includeArchived: boolean }>) => {
      state.importExport.submitting = true;
      state.importExport.submitted = false;
    },
    uploadBulkAssetsSuccessAction: (state) => {
      state.importExport.submitting = false;
      state.importExport.submitted = true;
    },
    uploadBulkAssetsFailAction: (state) => {
      state.importExport.submitting = false;
      state.importExport.submitted = false;
    },
    exportGlEntriesAction: (
      state,
      action: PayloadAction<{ propertyId: string; startDate: ReduxDate; endDate: ReduxDate }>,
    ) => {
      state.importExport.submitting = true;
      state.importExport.submitted = false;
    },
    exportGlEntriesSuccessAction: (state) => {
      state.importExport.submitting = false;
      state.importExport.submitted = true;
    },
    exportGlEntriesFailAction: (state) => {
      state.importExport.submitting = false;
      state.importExport.submitted = false;
    },
  },
});

export const {
  updateDocumentMetadataAction,
  updateDocumentMetadataSuccessAction,
  updateDocumentMetadataFailAction,
  downloadTemplateFailAction,
  downloadTemplateSuccessAction,
  downloadTemplateAction,
  uploadBulkAssetsSuccessAction,
  uploadBulkAssetsFailAction,
  uploadBulkAssetsAction,
  exportGlEntriesSuccessAction,
  exportGlEntriesFailAction,
  exportGlEntriesAction,
} = documentSlice.actions;

export const useDocuments = () => useSelector((r: RootState) => r.documents);
