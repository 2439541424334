import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ApartmentIcon from '@mui/icons-material/Apartment';
import BusinessIcon from '@mui/icons-material/Business';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import DoorFrontIcon from '@mui/icons-material/DoorFront';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ConstructionIcon from '@mui/icons-material/Construction';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ScheduleIcon from '@mui/icons-material/Schedule';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React from 'react';
import DescriptionIcon from '@mui/icons-material/Description';
import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { LeaseActionType } from '../leases/components/leaseTable/useLeaseActions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Forward, Key, NavigateBefore, PriceCheck, Task, Work } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import SaveIcon from '@mui/icons-material/Save';
import BlockIcon from '@mui/icons-material/Block';
import SendIcon from '@mui/icons-material/Send';
import Launch from '@mui/icons-material/Launch';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReportIcon from '@mui/icons-material/Report';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { SvgIconProps } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PaidIcon from '@mui/icons-material/Paid';
import BrushIcon from '@mui/icons-material/Brush';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import WebIcon from '@mui/icons-material/Web';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const useIcons = () => {
  const getAssociationIcon = (
    assetType?: AssociationType | AssociationChildType,
    props?: SvgIconProps<any>,
  ): JSX.Element => {
    switch (assetType) {
      case AssociationType.Application:
        return <ContactPageIcon {...props} />;
      case AssociationType.RentalProperty:
        return <ApartmentIcon {...props} />;
      case AssociationType.Building:
        return <BusinessIcon {...props} />;
      case AssociationType.BuildingUnit:
        return <HouseOutlinedIcon {...props} />;
      case AssociationType.Room:
        return <DoorFrontIcon {...props} />;
      case AssociationType.Lease:
        return <EmojiPeopleIcon {...props} />;
      case AssociationType.Task:
        return <ConstructionIcon {...props} />;
      case AssociationType.Expense:
        return <AttachMoneyIcon {...props} />;
      case AssociationType.Detail:
        return <ManageSearchIcon {...props} />;
      case AssociationType.ScheduledTask:
        return <ScheduleIcon {...props} />;
      case AssociationType.TaskConfiguration:
        return <FormatListNumberedIcon {...props} />;
      case AssociationType.Vendor:
        return <BadgeIcon {...props} />;
      case AssociationType.EmailThread:
        return <EmailIcon {...props} />;
      case AssociationType.Notification:
        return <NotificationsNoneIcon {...props} />;
      case AssociationChildType.ChecklistItem:
        return <PlaylistAddCheckIcon {...props} />;
      case AssociationChildType.Observation:
      case AssociationType.DetailObservation:
        return <VisibilityIcon {...props} />;
      case AssociationChildType.Invoice:
      case AssociationType.ExpenseInvoice:
      case AssociationChildType.Payment:
      case AssociationType.ExpensePayment:
      case AssociationChildType.PurchaseOrder:
      case AssociationType.ExpensePurchaseOrder:
      case AssociationChildType.WorkOrder:
      case AssociationType.ExpenseWorkOrder:
      case AssociationChildType.Quote:
      case AssociationType.ExpenseQuote:
        return <ReceiptIcon {...props} />;
      case AssociationType.JournalEntry:
        return <PaidIcon {...props} />;
      case AssociationType.UnitTemplate:
        return <BrushIcon {...props} />;
      default:
        return <></>;
    }
  };

  const getTabIcon = (tab: 'document' | 'comment' | 'info' | 'photo' | 'webView', props?: SvgIconProps<any>) => {
    switch (tab) {
      case 'document':
        return <DescriptionIcon {...props} />;
      case 'comment':
        return <CommentIcon {...props} />;
      case 'info':
        return <InfoIcon {...props} />;
      case 'photo':
        return <PhotoCameraIcon {...props} />;
      case 'webView':
        return <WebIcon {...props} />;
      default:
        return <></>;
    }
  };

  enum ActionType {
    Back = 'back',
    CreateTask = 'creatTask',
    Deposit = 'deposit',
    Cancel = 'cancel',
    Save = 'save',
    Block = 'block',
    Invite = 'invite',
    GoTo = 'goTo',
    Rollback = 'rollback',
    AssociateExpenseItem = 'associateExpenseItem',
    Close = 'close',
    Error = 'error',
    Edit = 'edit',
    Add = 'add',
    Delete = 'delete',
    PostEntry = 'postEntry',
    Statement = 'statement',
    Void = 'void',
  }

  const getActionIcon = (action: ActionType | LeaseActionType, props?: SvgIconProps<any>) => {
    switch (action) {
      case ActionType.CreateTask:
        return <ConstructionIcon {...props} />;
      case ActionType.Delete:
        return <DeleteIcon {...props} />;
      case ActionType.Deposit:
      case LeaseActionType.AcceptDeposit:
      case LeaseActionType.RectifyDeposit:
        return <LocalAtmIcon {...props} />;
      case LeaseActionType.RenewLease:
        return <AutorenewIcon {...props} />;
      case LeaseActionType.MarkAsSigned:
        return <Task {...props} />;
      case LeaseActionType.PreLease:
        return <Forward {...props} />;
      case LeaseActionType.MarkAsLeased:
        return <Key {...props} />;
      case LeaseActionType.CancelPreLease:
      case ActionType.Cancel:
      case LeaseActionType.BeginEviction:
        return <CancelIcon {...props} />;
      case LeaseActionType.MoveOut:
        return <Work {...props} />;
      case LeaseActionType.RollbackLease:
        return <NavigateBefore {...props} />;
      case ActionType.Rollback:
        return <RotateLeftIcon {...props} />;
      case LeaseActionType.CloseLease:
      case ActionType.Close:
        return <CloseIcon {...props} />;
      case LeaseActionType.ReturnFunds:
        return <RequestQuoteIcon {...props} />;
      case ActionType.Save:
        return <SaveIcon {...props} />;
      case ActionType.Block:
        return <BlockIcon {...props} />;
      case ActionType.Invite:
        return <SendIcon {...props} />;
      case ActionType.GoTo:
        return <Launch {...props} />;
      case ActionType.AssociateExpenseItem:
        return <ReceiptIcon {...props} />;
      case ActionType.Error:
        return <ReportIcon {...props} />;
      case ActionType.Add:
        return <AddIcon {...props} />;
      case ActionType.Edit:
        return <EditIcon {...props} />;
      case ActionType.PostEntry:
        return <PriceCheck {...props} />;
      case ActionType.Statement:
        return <ListAltIcon {...props} />;
      case ActionType.Void:
        return <BlockIcon {...props} />;
      case ActionType.Back:
        return <ArrowBackIosIcon {...props} />;
      default:
        return <></>;
    }
  };

  return { getAssociationIcon, ActionType, getActionIcon, getTabIcon };
};
