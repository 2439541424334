import React, { FC, FormEvent, useEffect, useState } from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { DialogLayout } from '../../../_shared/dialogs/DialogLayout';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  addTenantCommentToTaskAction,
  updateTenantTaskAction,
  uploadTenantDocToTaskAction,
  useTenant,
} from '../../redux/tenantSlice';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { StyledInfoBox } from '../../../_shared/styledComponents/StyledInfoBox';
import { DocumentList } from '../../../_shared/documents/components/DocumentList';
import { CommentList } from '../../../_shared/comments/CommentList';
import { ReduxTenantTask, TaskStatusMap } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { ReadOnlyTimeConfiguration } from '../../../_shared/tasks/components/scheduledTasks/ReadOnlyTimeConfiguration';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';

type EditMaintenanceRequestDialogProps = Omit<DialogProps, 'onClose'> & {
  onClose: () => void;
  selectedLeaseId?: string;
};

export const EditMaintenanceRequestDialog: FC<EditMaintenanceRequestDialogProps> = ({
  open,
  onClose,
  selectedLeaseId,
}) => {
  const dispatch = useDispatch();
  const { maintenanceRequests } = useTenant();
  const { selectedContext } = useAssets();
  const [editingValue, setEditingValue] = useState<ReduxTenantTask | undefined>(maintenanceRequests.selectedValue);
  const [dirty, setDirty] = useState(false);
  const [currentTab, setCurrentTab] = useState('info');

  useEffect(() => {
    setEditingValue(maintenanceRequests.selectedValue);
  }, [maintenanceRequests.selectedValue]);

  useEffect(() => {
    maintenanceRequests.submitted && setDirty(false);
  }, [maintenanceRequests.submitted]);

  const handleEditMaintenanceRequest = (e: FormEvent) => {
    e.preventDefault();
    if (!editingValue?.id || !selectedLeaseId) return;
    dispatch(
      updateTenantTaskAction({
        leaseId: selectedLeaseId,
        taskId: editingValue?.id,
        body: editingValue,
      }),
    );
  };

  const handleClose = () => {
    onClose();
    setDirty(false);
  };

  const handleUpload = (file: File) => {
    if (!editingValue?.id || !selectedLeaseId) return;
    dispatch(
      uploadTenantDocToTaskAction({
        taskId: editingValue.id,
        leaseId: selectedLeaseId,
        file,
      }),
    );
  };

  const handleAddComment = (comment: string) => {
    if (!editingValue?.id || !selectedLeaseId) return;
    dispatch(
      addTenantCommentToTaskAction({
        taskId: editingValue?.id,
        leaseId: selectedLeaseId,
        comment,
      }),
    );
  };

  return (
    <DialogLayout
      open={open}
      onClose={handleClose}
      title={'View/Update Maintenance Request'}
      aria-labelledby="edit-maintenance-request-title"
      fullWidth
      maxWidth={'md'}
      PaperProps={{ sx: { height: '60%', minHeight: 480 } }}
    >
      <form onSubmit={handleEditMaintenanceRequest}>
        <DialogContent>
          <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)}>
            <Tab value="info" label="Info" />
            <Tab value="documents" label="Documents" />
            <Tab value="comments" label="Comments" />
          </Tabs>
          {
            {
              info: (
                <StyledInfoBox label={'Info'}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant={'outlined'}
                        label={'Task Name'}
                        sx={{ margin: 0 }}
                        value={editingValue?.name ? editingValue.name : ''}
                        onChange={(e) => {
                          setDirty(true);
                          setEditingValue({ ...editingValue, name: e.target.value });
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant={'outlined'}
                        label={'Status'}
                        inputProps={{ readonly: true }}
                        sx={{ margin: 0 }}
                        value={editingValue?.status ? TaskStatusMap.get(editingValue?.status) : 'Unknown'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        variant={'outlined'}
                        label={'Details about the issue'}
                        sx={{ margin: 0 }}
                        value={editingValue?.description ? editingValue.description : ''}
                        onChange={(e) => {
                          setDirty(true);
                          setEditingValue({ ...editingValue, description: e.target.value });
                        }}
                      />
                    </Grid>
                    {editingValue?.timeConfiguration && (
                      <Grid item xs={12}>
                        <ReadOnlyTimeConfiguration timeConfiguration={editingValue.timeConfiguration} />
                      </Grid>
                    )}
                  </Grid>
                </StyledInfoBox>
              ),
              documents: (
                <StyledInfoBox label={'Documents'}>
                  <DocumentList
                    propertyId={selectedContext.propertyId}
                    association={{
                      associatedId: editingValue?.id,
                      associationType: AssociationType.Task,
                    }}
                    value={editingValue?.documents ?? []}
                    onUpload={handleUpload}
                    uploading={maintenanceRequests.submitting}
                  />
                </StyledInfoBox>
              ),
              comments: (
                <StyledInfoBox label={'Comments'}>
                  <CommentList
                    comments={editingValue?.comments ?? []}
                    onAddComment={handleAddComment}
                    loading={maintenanceRequests.submitting}
                  />
                </StyledInfoBox>
              ),
            }[currentTab || 'info']
          }
        </DialogContent>
        <DialogActions>
          <Button variant={'outlined'} onClick={handleClose}>
            Back
          </Button>
          <LoadingButton
            loading={maintenanceRequests.submitting}
            variant={'contained'}
            type={'submit'}
            disabled={!dirty}
          >
            Update Maintenance Request
          </LoadingButton>
        </DialogActions>
      </form>
    </DialogLayout>
  );
};
