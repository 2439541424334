import React, { FC } from 'react';
import { ListItem, Box, Stack, Typography, IconButton, Tooltip } from '@mui/material';
import { ReduxAccount } from '@monkeyjump-labs/cam-fe-shared/dist/types/propertyTypes';
import { useIcons } from '../../../../_shared/icons/useIcons';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthorizedClaims } from '../../../../auth/AuthorizedClaims';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';

type AccountListItemProps = {
  account: ReduxAccount;
  onEnabledChange: (id: string, checked: boolean) => void;
  isAuthorized: boolean;
  onEdit: (id: string) => void;
};

const getIndentation = (account: ReduxAccount) => {
  return account.hierarchy.length;
};

export const AccountListItem: FC<AccountListItemProps> = ({ account, onEnabledChange, isAuthorized, onEdit }) => {
  const { getActionIcon, ActionType } = useIcons();
  const { id, innerTab, outerTab } = useParams();
  const navigate = useNavigate();

  const indentation = account.accountNumber ? getIndentation(account) * 20 : 20; // 20px per level of indentation

  return (
    <ListItem sx={{ display: 'flex', flexGrow: 1, pl: `${indentation}px` }}>
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Stack spacing={1}>
          {isAuthorized ? (
            <Link sx={{ cursor: 'pointer' }} color="inherit">
              <Typography
                variant={'body2'}
                onClick={() => {
                  navigate(`/assets/property/${id}/${outerTab}/${innerTab}/statement/${account.id}`);
                }}
              >
                {account.accountName}
              </Typography>
            </Link>
          ) : (
            account.accountName
          )}
          <Typography variant={'body2'}>Balance: ${account.balance?.toFixed(2)}</Typography>
        </Stack>
      </Box>
      <Stack direction={'row'} spacing={2}>
        <Box display={'flex'} alignItems={'center'}>
          <AuthorizedClaims allowedClaims={['Admin', 'BillingOwner', 'PropertyManager']}>
            {(account.isToggleable || !account.isEnabled) && (
              <FormControlLabel
                control={
                  <Switch
                    checked={account.isEnabled}
                    onChange={(e, checked) => {
                      account.id && onEnabledChange(account.id, checked);
                    }}
                    disabled={!account.isEnabled && !account.isToggleable}
                  />
                }
                label={!account.isEnabled && !account.isToggleable ? 'Disabled' : 'Enabled'}
              />
            )}
          </AuthorizedClaims>
        </Box>
        <Tooltip title={'Edit'}>
          <IconButton onClick={() => account.id && onEdit(account.id)}>{getActionIcon(ActionType.Edit)}</IconButton>
        </Tooltip>
      </Stack>
    </ListItem>
  );
};
