import React from 'react';
import { removeDocumentAction, uploadDocumentAction, useApplications } from '../../../../redux/applicationSlice';
import { useDispatch } from 'react-redux';
import { DocumentList } from '../../../../../_shared/documents/components/DocumentList';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const ApplicationDocuments = () => {
  const dispatch = useDispatch();
  const { selectedApplication } = useApplications();
  const handleFileUpload = (file: File) => {
    if (selectedApplication.value?.id && selectedApplication.value.rentalPropertyId)
      dispatch(
        uploadDocumentAction({
          applicationId: selectedApplication.value.id,
          file,
        }),
      );
  };
  const handleRemoveDocument = (id: string) => {
    if (selectedApplication.value?.id) {
      dispatch(
        removeDocumentAction({
          applicationId: selectedApplication.value.id,
          documentId: id,
        }),
      );
    }
  };
  return (
    <StyledInfoBox label={'Documents'}>
      <DocumentList
        propertyId={selectedApplication.value?.rentalPropertyId}
        association={{
          associatedId: selectedApplication.value?.id,
          associationType: AssociationType.Application,
        }}
        value={selectedApplication.value?.documents ?? []}
        onUpload={handleFileUpload}
        uploading={selectedApplication.submitting}
        onDeleteDocument={handleRemoveDocument}
      />
    </StyledInfoBox>
  );
};
