import React, { FC, useRef, useState } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import { Grow } from '@mui/material';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { AuthorizedClaims } from '../../../auth/AuthorizedClaims';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { tryFormatDate } from '../../utils/TryFormatDate';
import {
  ExternalProcessor,
  IExternalTransaction,
  JournalEntryStatus,
  PaymentStage,
  PaymentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import { getStatementLineDetailByJournalEntryIdAction } from '../redux/statementSlice';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { TableRow } from '../useStatementConfig';
import { dialogChildIsOpenAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { camelCaseToWords } from '../../utils/camelCaseUtils';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import { currencyFormatter } from '../../utils/currencyFormatter';

type PaymentStatusChipProps = {
  row: TableRow;
  value?: string;
  isTenantPortalView?: boolean;
  disableJournalEntryView?: boolean;
};
export const PaymentStatusChip: FC<PaymentStatusChipProps> = ({
  row,
  value,
  isTenantPortalView,
  disableJournalEntryView,
}) => {
  const dispatch = useDispatch();
  const chipRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const userClaims = useUser((u) => u.currentUser.value?.claims);
  const userRoles = useUser((u) => u.currentUser.value?.roles);
  const userIsSuperAdmin = userRoles?.some((c) => c === 'SUPERADMIN');
  const userIsAccountant = userClaims?.some((c) => c.type === Claims.Accountant);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createExternalTransactionUrls = (stage: IExternalTransaction): string | undefined => {
    if (!stage) return undefined;
    const authorizeNetUrl =
      process.env.REACT_APP_API_URL.includes('localhost') || process.env.REACT_APP_API_URL.includes('stage')
        ? 'https://sandbox.authorize.net/ui/themes/sandbox/Transaction/TransactionReceipt.aspx?transid='
        : 'https://account.authorize.net/ui/themes/anet/transaction/transactiondetail.aspx?transID=';

    const modernTreasuryUrl = `https://app.moderntreasury.com/payment_orders/`;

    switch (stage.processor) {
      case ExternalProcessor.AuthorizeNet:
        return authorizeNetUrl + stage.externalId;
      case ExternalProcessor.ModernTreasury:
        return modernTreasuryUrl + stage.externalId;
      default:
        return '';
    }
  };

  const getStage1 = (externalTransactions: IExternalTransaction[]) => {
    return externalTransactions.find((t) => t.paymentStage == PaymentStage.Stage1);
  };

  const getPaymentInfo = (externalTransactions: IExternalTransaction[]): string => {
    if (!isTenantPortalView) return camelCaseToWords(row.paymentStatus);
    const stage1 = getStage1(externalTransactions);
    if (stage1 && stage1.dateCompleted) return 'Completed';
    return 'Pending';
  };

  const stageMap = new Map<PaymentStage, string>([
    [PaymentStage.Stage1, 'Tenant Payment'],
    [PaymentStage.Stage2, 'Disbursement'],
    [PaymentStage.DecreasePropertyFloat, 'Decrease Float Account'],
    [PaymentStage.IncreasePropertyFloat, 'Increase Float Account'],
    [PaymentStage.TechnologyFeeCharge, 'Charge Technology Fee'],
    [PaymentStage.TechnologyFeeVoid, 'Void Technology Fee'],
  ]);

  const createRows = (externalTransactions: IExternalTransaction[]) => {
    const stage1 = getStage1(externalTransactions);
    if (!stage1) return undefined; // this should not happen

    const transactions = isTenantPortalView ? [stage1] : externalTransactions;

    return transactions.map((et, index) => {
      return {
        ...et,
        id: index,
        type: camelCaseToWords(et.paymentType === PaymentType.CreditCard ? et.cardOrAccountType : et.paymentType),
        stage: stageMap.get(et.paymentStage!),
        account: et.cardOrAccountNumber,
        url: createExternalTransactionUrls(et),
        ...(et.dateFailed ? { failedInfo: `${tryFormatDate(et.dateFailed.toString())} - ${et.failedReason}` } : {}),
      };
    });
  };

  const createColumns = (externalTransactions: IExternalTransaction[]): GridColDef[] => {
    const columns: GridColDef[] = [
      { field: 'stage', headerName: 'Stage', minWidth: 150 },
      {
        field: 'type',
        headerName: 'Type',
        align: 'center',
        minWidth: 125,
      },
      { field: 'account', headerName: 'Account' },
      {
        field: 'amount',
        headerName: 'Amount',
        type: 'number',
        valueFormatter: (value: never) => currencyFormatter.format(value),
      },
      {
        field: 'dateStarted',
        headerName: 'Initiated',
        type: 'date',
        valueGetter: (value: never) => value && new Date(value),
      },
      {
        field: 'dateCompleted',
        headerName: 'Completed',
        type: 'date',
        valueGetter: (value: never) => value && new Date(value),
      },
    ];
    const isFailed = externalTransactions.some((et) => et.dateFailed);
    if (isFailed) {
      columns.push({
        field: 'failedInfo',
        headerName: 'Failed',
        type: 'string',
        minWidth: 250,
      });
    }
    if (userIsSuperAdmin) {
      const linkColumn: GridColDef = {
        field: 'url',
        headerName: 'Link',
        maxWidth: 50,
        renderCell: (params) => {
          // only return a link when href is defined
          return params.value ? (
            <Link href={params.value} target="_blank" rel="noopener noreferrer">
              <OpenInNewIcon />
            </Link>
          ) : null;
        },
      };
      columns.splice(1, 0, linkColumn);
    }

    return columns;
  };

  return (
    <Stack ref={chipRef} direction={'row'} spacing={2} justifyItems={'center'}>
      {row.externalTransactions && row.externalTransactions.length > 0 && (
        <>
          <Chip
            sx={{ marginRight: 2 }}
            size="small"
            variant={'outlined'}
            color={'primary'}
            label={getPaymentInfo(row.externalTransactions!)}
            onClick={handleClick}
          />
          <Popper sx={{ zIndex: 999999 }} open={open} anchorEl={chipRef.current} transition>
            {({ TransitionProps, placement }) => {
              return (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box padding={2}>
                        <Typography variant="subtitle1">Payment Details</Typography>
                        <Typography variant="subtitle2">
                          {row.releaseDate
                            ? `Release Date: ${tryFormatDate(row.releaseDate)}`
                            : row.expectedReleaseDate
                              ? `Expected Release Date: ${tryFormatDate(row.expectedReleaseDate)}`
                              : ''}
                        </Typography>
                        <DataGridPremium
                          density="compact"
                          columns={createColumns(row.externalTransactions!)}
                          rows={createRows(row.externalTransactions!)}
                          sx={{ flex: 1 }}
                          hideFooter
                        />
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              );
            }}
          </Popper>
        </>
      )}
      <Box
        sx={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          lineHeight: 'normal',
        }}
      >
        {(userIsAccountant || userIsSuperAdmin) && !disableJournalEntryView && row.path?.length === 1 ? (
          <AuthorizedClaims allowedClaims={['Accountant']}>
            <Tooltip title={'View Transactions'}>
              <Link sx={{ cursor: 'pointer' }}>
                <Typography
                  variant={'body2'}
                  fontStyle={row.status === JournalEntryStatus.Pending ? 'italic' : 'normal'}
                  sx={
                    row.status === JournalEntryStatus.Archived
                      ? { color: 'error.main', textDecoration: 'line-through' }
                      : undefined
                  }
                  onClick={() => {
                    row.id && dispatch(getStatementLineDetailByJournalEntryIdAction(row.id));
                    dispatch(dialogChildIsOpenAction(true));
                  }}
                >
                  {value}
                </Typography>
              </Link>
            </Tooltip>
          </AuthorizedClaims>
        ) : (
          <Typography variant={'body2'}>{value}</Typography>
        )}
      </Box>
    </Stack>
  );
};
