import React, { FC, useEffect, useState } from 'react';
import { DocumentList } from '../../../documents/components/DocumentList';
import {
  addCommentToInvoiceAction,
  addCommentToPaymentAction,
  addCommentToPurchaseOrderAction,
  addCommentToQuoteAction,
  addCommentToWorkOrderAction,
  addDocToInvoiceAction,
  addDocToPurchaseOrderAction,
  addDocToQuoteAction,
  addDocToWorkOrderAction,
  addDocumentToPaymentAction,
  useExpenses,
} from '../../redux/expenseSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import { CommentList } from '../../../comments/CommentList';
import {
  ExpenseElementType,
  ReduxExpensePayment,
  ReduxInvoice,
  ReduxPurchaseOrder,
  ReduxQuote,
  ReduxWorkOrder,
} from '../../redux/expenseData';
import { ExpenseElementInfoTab } from './ExpenseElementInfoTab';
import { Emails } from '../../../communications/components/Emails';
import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

type JobElementDetailsProps = {
  tabValue: string;
  onAddNewVendor: (name: string) => void;
  type: ExpenseElementType;
  onPOLineItemAssociation: (lineItemNumber: string) => void;
};

export const ExpenseElementDetails: FC<JobElementDetailsProps> = ({
  tabValue,
  onAddNewVendor,
  type,
  onPOLineItemAssociation,
}) => {
  const { selectedPayment, selectedInvoice, selectedExpense, selectedQuote, selectedPurchaseOrder, selectedWorkOrder } =
    useExpenses();
  const { selectedContext } = useAssets();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<
    ReduxExpensePayment | ReduxInvoice | ReduxQuote | ReduxPurchaseOrder | ReduxWorkOrder | undefined
  >();
  const childType =
    type === ExpenseElementType.Payment
      ? AssociationChildType.Payment
      : type === ExpenseElementType.Invoice
        ? AssociationChildType.Invoice
        : type === ExpenseElementType.Quote
          ? AssociationChildType.Quote
          : type === ExpenseElementType.PurchaseOrder
            ? AssociationChildType.PurchaseOrder
            : type === ExpenseElementType.WorkOrder
              ? AssociationChildType.WorkOrder
              : undefined;
  useEffect(() => {
    switch (type) {
      case ExpenseElementType.Payment:
        setLoading(selectedPayment.submitting);
        setValue(selectedPayment.value);
        break;
      case ExpenseElementType.Quote:
        setLoading(selectedQuote.submitting);
        setValue(selectedQuote.value);
        break;
      case ExpenseElementType.Invoice:
        setLoading(selectedInvoice.submitting);
        setValue(selectedInvoice.value);
        break;
      case ExpenseElementType.WorkOrder:
        setLoading(selectedWorkOrder.submitting);
        setValue(selectedWorkOrder.value);
        break;
      case ExpenseElementType.PurchaseOrder:
        setLoading(selectedPurchaseOrder.submitting);
        setValue(selectedPurchaseOrder.value);
        break;
      default:
        break;
    }
  }, [
    type,
    selectedPayment.submitting,
    selectedInvoice.submitting,
    selectedQuote.submitting,
    selectedPurchaseOrder.submitting,
    selectedWorkOrder.submitting,
    selectedPayment.value,
    selectedInvoice.value,
    selectedQuote.value,
    selectedPurchaseOrder.value,
    selectedWorkOrder.value,
  ]);

  const handleDocumentUpload = (file: File) => {
    if (!selectedExpense.value?.id) return;
    switch (type) {
      case ExpenseElementType.Payment:
        selectedPayment.value?.number &&
          dispatch(
            addDocumentToPaymentAction({
              jobId: selectedExpense.value?.id,
              paymentNumber: selectedPayment.value?.number,
              file: file,
            }),
          );
        break;
      case ExpenseElementType.Invoice:
        selectedInvoice.value?.number &&
          dispatch(
            addDocToInvoiceAction({
              jobId: selectedExpense.value?.id,
              invoiceNumber: selectedInvoice.value?.number,
              file: file,
            }),
          );
        break;
      case ExpenseElementType.WorkOrder:
        selectedWorkOrder.value?.number &&
          dispatch(
            addDocToWorkOrderAction({
              jobId: selectedExpense.value?.id,
              woNumber: selectedWorkOrder.value?.number,
              file: file,
            }),
          );
        break;
      case ExpenseElementType.PurchaseOrder:
        selectedPurchaseOrder.value?.number &&
          dispatch(
            addDocToPurchaseOrderAction({
              jobId: selectedExpense.value?.id,
              poNumber: selectedPurchaseOrder.value?.number,
              file: file,
            }),
          );
        break;
      case ExpenseElementType.Quote:
        selectedQuote.value?.number &&
          dispatch(
            addDocToQuoteAction({
              jobId: selectedExpense.value?.id,
              quoteNumber: selectedQuote.value?.number,
              file: file,
            }),
          );
        break;
      default:
        break;
    }
  };

  const handleAddComment = (comment: string) => {
    if (!selectedExpense.value?.id) return;
    switch (type) {
      case ExpenseElementType.Payment:
        selectedPayment.value?.number &&
          dispatch(
            addCommentToPaymentAction({
              jobId: selectedExpense.value.id,
              paymentNumber: selectedPayment.value.number,
              comment: comment,
            }),
          );
        break;
      case ExpenseElementType.Invoice:
        selectedInvoice.value?.number &&
          dispatch(
            addCommentToInvoiceAction({
              jobId: selectedExpense.value.id,
              invoiceNumber: selectedInvoice.value.number,
              comment: comment,
            }),
          );
        break;
      case ExpenseElementType.WorkOrder:
        selectedWorkOrder.value?.number &&
          dispatch(
            addCommentToWorkOrderAction({
              jobId: selectedExpense.value.id,
              woNumber: selectedWorkOrder.value.number,
              comment: comment,
            }),
          );
        break;
      case ExpenseElementType.PurchaseOrder:
        selectedPurchaseOrder.value?.number &&
          dispatch(
            addCommentToPurchaseOrderAction({
              jobId: selectedExpense.value.id,
              poNumber: selectedPurchaseOrder.value.number,
              comment: comment,
            }),
          );
        break;
      case ExpenseElementType.Quote:
        selectedQuote.value?.number &&
          dispatch(
            addCommentToQuoteAction({
              jobId: selectedExpense.value.id,
              quoteNumber: selectedQuote.value.number,
              comment: comment,
            }),
          );
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ p: '1rem', width: '100%' }}>
      {
        {
          info: (
            <ExpenseElementInfoTab
              type={type}
              onAddNewVendor={onAddNewVendor}
              jobId={selectedExpense.value?.id}
              propertyId={selectedContext.propertyId}
              value={value}
              onPOLineItemAssociation={onPOLineItemAssociation}
            />
          ),
          documents: (
            <DocumentList
              propertyId={selectedContext.propertyId}
              association={{
                associationChildNumber: value?.number,
                associationChildType: childType,
                associatedId: selectedExpense.value?.id,
                associationType: AssociationType.Expense,
              }}
              value={value?.documents ?? []}
              onUpload={handleDocumentUpload}
              uploading={loading}
            />
          ),
          comments: <CommentList comments={value?.comments ?? []} onAddComment={handleAddComment} loading={loading} />,
          communication: (
            <Emails
              associationInfo={{
                associatedId: selectedExpense?.value?.id,
                associationType: AssociationType.Expense,
                childType: childType,
                childNumber: value?.number,
                emailAddresses: [],
                label: selectedExpense?.value?.name ?? 'Expense',
              }}
            />
          ),
        }[tabValue ?? 'details']
      }
    </Box>
  );
};
