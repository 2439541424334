import { put, takeLatest } from 'redux-saga/effects';
import { getEventsAction, getEventsFailAction, getEventsSuccessAction } from './calendarSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AssetType,
  ICalendarEvent,
  IListEventsForPropertyHandlerRequest,
  ListEventsForPropertyHandlerRequest,
  ListEventsForPropertyHandlerResponse,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { mapReduxCalendarEvent } from './calendarData';

function* getEvents(
  action: PayloadAction<{ assetId: string; assetType: AssetType; body: IListEventsForPropertyHandlerRequest }>,
) {
  try {
    const response: ListEventsForPropertyHandlerResponse = yield apiCall(
      ApiClientSingleton.getInstance().calendar_ListCalendarEventsForAsset,
      action.payload.assetId,
      action.payload.assetType,
      new ListEventsForPropertyHandlerRequest(action.payload.body),
    );
    if (response) {
      yield put(
        getEventsSuccessAction(
          response.toJSON().events.map((calendarEvent: ICalendarEvent) => {
            return mapReduxCalendarEvent(calendarEvent);
          }),
        ),
      );
    }
  } catch (error) {
    yield put(getEventsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem getting calendar events' }));
  }
}

export function* calendarSaga() {
  yield takeLatest(getEventsAction.type, getEvents);
}
