import React, { FC, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { CSSObject } from '@mui/material/styles';
import {
  AssociationChildType,
  AssociationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { DocumentList } from '../../documents/components/DocumentList';
import { CommentList } from '../../comments/CommentList';
import { ReduxChecklistItem } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { useIcons } from '../../icons/useIcons';
import { ExpandMore } from '../../styledComponents/ExpandMore';
import { DraggableComponent } from '../../dragAndDrop/DraggableComponent';
import { useTheme } from '@mui/material';
import { selectedItemBorder } from '../../../../AppTheme';

type ChecklistItemProps = {
  propertyId?: string;
  value: ReduxChecklistItem;
  index: number;
  onDeleteItem: (id: string) => void;
  onChecked?: (id: string) => void;
  loading: boolean;
  associatedId?: string;
  associationType: AssociationType;
  childAssociatedId?: string;
  childAssociationType?: AssociationType;
  onAddComment: (comment: string, itemId: string) => void;
  onUploadDocument: (file: File, itemId: string) => any;
  onDeleteDocument: (docId: string, itemId: string) => void;
  onUpdateChecklistItem: (itemId: string, newName?: string, newDescription?: string) => void;
};

export const ChecklistItem: FC<ChecklistItemProps> = ({
  propertyId,
  value,
  index,
  onDeleteItem,
  onChecked,
  loading,
  associationType,
  associatedId,
  onAddComment,
  onDeleteDocument,
  onUploadDocument,
  onUpdateChecklistItem,
}) => {
  const { getActionIcon, ActionType } = useIcons();
  const theme = useTheme();
  const labelId = `checkbox-list-label-${value.name}`;
  const [expandedListItems, setExpandedListItems] = useState<string[]>([]);
  const [editingName, setEditingName] = useState(value.name);
  const [editingDescription, setEditingDescription] = useState(value.description);
  const highlightedChecklistItem = new URLSearchParams(location.search).get('selected');
  console.log('highlightedChecklistItem', highlightedChecklistItem, 'value.id', value.id);

  const getItemStyle = (isDragging?: boolean): CSSObject => ({
    pl: onChecked ? '0rem' : '1rem',
    backgroundColor: isDragging ? theme.palette.primary.light : 'inherit',
    border: highlightedChecklistItem === value.id ? selectedItemBorder : 'inherit',
  });

  const handleExpandListItem = (id: string) => {
    if (expandedListItems.includes(id)) {
      setExpandedListItems(expandedListItems.filter((i) => i !== id));
    } else {
      setExpandedListItems([...expandedListItems, id]);
    }
  };

  const handleDeleteDocument = (docId: string) => {
    onDeleteDocument(docId, value.id!);
  };

  return (
    <DraggableComponent id={value.id!} index={index} customItemStyle={({}, isDragging) => getItemStyle(isDragging)}>
      <ListItem
        secondaryAction={
          value.id &&
          value.id !== '00000000-0000-0000-0000-000000000000' && (
            <>
              <Tooltip title={'Delete Item'}>
                <IconButton edge="end" aria-label="delete" onClick={() => value.id && onDeleteItem(value.id)}>
                  {getActionIcon(ActionType.Delete)}
                </IconButton>
              </Tooltip>
              <Tooltip title={'View/Edit Item'}>
                <span>
                  <ExpandMore
                    expand={expandedListItems.includes(value.id!)}
                    onClick={() => handleExpandListItem(value.id!)}
                    aria-expanded={expandedListItems.includes(value.id!)}
                  />
                </span>
              </Tooltip>
            </>
          )
        }
        disablePadding
        disabled={value.isComplete}
      >
        {onChecked ? (
          <ListItemButton role={undefined} onClick={() => value.id && onChecked(value.id)} dense>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={value.isComplete}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </ListItemIcon>
            <ListItemText
              id={labelId}
              primary={value.name}
              primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
              sx={{ textDecoration: value.isComplete ? 'line-through' : 'none' }}
            />
          </ListItemButton>
        ) : (
          <ListItemText
            id={labelId}
            primary={value.name}
            primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
            sx={{ textDecoration: value.isComplete ? 'line-through' : 'none' }}
          />
        )}
      </ListItem>
      <Collapse in={expandedListItems.includes(value.id!)}>
        <CardContent>
          <Divider />
          <Stack sx={{ p: 1 }}>
            <TextField value={editingName ?? ''} onChange={(e) => setEditingName(e.target.value)} label={'Name'} />
            <TextField
              value={editingDescription ?? ''}
              onChange={(e) => setEditingDescription(e.target.value)}
              label={'Description'}
              multiline
              minRows={2}
            />
            <Box display={'flex'} flexGrow={1} justifyContent={'right'}>
              <Tooltip title={'Save Changes'}>
                <span>
                  <IconButton
                    disabled={editingName === value.name && editingDescription === value.description}
                    onClick={() => onUpdateChecklistItem(value.id!, editingName, editingDescription)}
                  >
                    {getActionIcon(ActionType.Save)}
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Stack>
          <Divider />
          <Box sx={{ p: 1 }}>
            <Typography sx={{ py: 1, fontStyle: 'italic' }}>
              {value.documents?.length === 0 ? 'No Attached Files' : 'Attached Files'}
            </Typography>
            <DocumentList
              propertyId={propertyId}
              value={value.documents ?? []}
              onUpload={(file: File) => onUploadDocument(file, value.id!)}
              association={{
                associatedId: associatedId,
                associationType: associationType,
                associationChildNumber: value.id,
                associationChildType: AssociationChildType.ChecklistItem,
              }}
              uploading={loading}
              onDeleteDocument={handleDeleteDocument}
            />
          </Box>
          <Divider />
          <Box sx={{ p: 1 }}>
            <Typography sx={{ pt: 1, fontStyle: 'italic' }}>Comments</Typography>
            <CommentList
              comments={value.comments ?? []}
              onAddComment={(comment) => onAddComment(comment, value.id!)}
              loading={loading}
            />
          </Box>
        </CardContent>
      </Collapse>
    </DraggableComponent>
  );
};
