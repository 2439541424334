import React, { FC, useEffect, useState } from 'react';
import { ExpenseElementType } from '../../redux/expenseData';
import { useExpenses } from '../../redux/expenseSlice';
import {
  AssociationType,
  IAddVendorHandlerRequest,
  IVendor,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ExpenseElementDetails } from './ExpenseElementDetails';
import { VendorInfoForm } from '../../../../property/components/propertyTabs/propertyInfoTabs/vendorManagement/VendorInfoForm';
import { DrawerLayout, DrawerNavItem } from '../../../../global/layout/DrawerLayout';
import {
  addVendorForPropertyAction,
  clearVendorSubmissionAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Box from '@mui/material/Box';
import { useIcons } from '../../../icons/useIcons';

type JobDetailsDrawerProps = {
  open: boolean;
  onCloseDrawer: () => void;
  type: ExpenseElementType | undefined;
  newVendor?: IVendor;
  onAddNewVendor: (name: string) => void;
  addingElement: boolean;
  onOpenAddElement: () => void;
  onClearNewVendor: () => void;
  onPOLineItemAssociation: (lineItemNumber: string) => void;
};

export const ExpenseDrawer: FC<JobDetailsDrawerProps> = ({
  open,
  onCloseDrawer,
  type,
  newVendor,
  onAddNewVendor,
  addingElement,
  onOpenAddElement,
  onClearNewVendor,
  onPOLineItemAssociation,
}) => {
  const dispatch = useDispatch();
  const { getAssociationIcon, getTabIcon } = useIcons();
  const { selectedContext } = useAssets();
  const { selectedProperty, vendors } = useProperty();
  const { selectedPayment, selectedQuote, selectedWorkOrder, selectedPurchaseOrder, selectedInvoice } = useExpenses();
  const [title, setTitle] = useState('');
  const [tabValue, setTabValue] = React.useState('info');

  useEffect(() => {
    if (newVendor) {
      setTitle('Add Vendor');
    } else {
      switch (type) {
        case ExpenseElementType.Quote:
          setTitle(selectedQuote?.value?.name ? `Quote: ${selectedQuote?.value?.name}` : 'Quote');
          break;
        case ExpenseElementType.Invoice:
          setTitle(selectedInvoice?.value?.name ? `Invoice: ${selectedInvoice?.value?.name}` : 'Invoice');
          break;
        case ExpenseElementType.Payment:
          setTitle(selectedPayment?.value?.name ? `Payment: ${selectedPayment?.value?.name}` : 'Payment');
          break;
        case ExpenseElementType.WorkOrder:
          setTitle(selectedWorkOrder?.value?.name ? `Work Order: ${selectedWorkOrder?.value?.name}` : 'Work Order');
          break;
        case ExpenseElementType.PurchaseOrder:
          setTitle(
            selectedPurchaseOrder?.value?.name
              ? `Purchase Order: ${selectedPurchaseOrder?.value?.name}`
              : 'Purchase Order',
          );
          break;
        default:
          setTitle('');
      }
    }
  }, [newVendor, type, selectedPayment, selectedInvoice, selectedPurchaseOrder, selectedQuote, selectedWorkOrder]);

  const handleClose = () => {
    setTabValue('info');
    onCloseDrawer();
  };

  useEffect(() => {
    if (selectedProperty.submitted) {
      //reopen addElementDialog
      if (addingElement && onOpenAddElement) onOpenAddElement();
      //else clear the newVendor, which will nav back to the details drawer
      else onClearNewVendor();
    }
    dispatch(clearVendorSubmissionAction());
  }, [selectedProperty.submitted]);

  const handleVendorSubmitted = (vendor: IAddVendorHandlerRequest) => {
    selectedContext.propertyId &&
      dispatch(
        addVendorForPropertyAction({
          propertyId: selectedContext.propertyId,
          body: vendor,
        }),
      );
  };

  const tabs: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: 'info',
    },
    {
      icon: getTabIcon('document'),
      label: 'Documents',
      value: 'documents',
    },
    {
      icon: getTabIcon('comment'),
      label: 'Comments',
      value: 'comments',
    },
    {
      icon: getAssociationIcon(AssociationType.EmailThread),
      label: 'Communication',
      value: 'communication',
    },
  ];

  return (
    <DrawerLayout
      title={title}
      open={open}
      onCloseDrawer={handleClose}
      tabs={!newVendor ? tabs : undefined}
      tabValue={!newVendor ? tabValue : undefined}
      onTabChange={(newValue: string) => setTabValue(newValue)}
    >
      <>
        {newVendor ? (
          <Box margin={1}>
            <VendorInfoForm
              value={newVendor}
              onSubmit={handleVendorSubmitted}
              submitting={vendors.submitting}
              newOrEdit={'new'}
            />
          </Box>
        ) : (
          <ExpenseElementDetails
            tabValue={tabValue}
            onAddNewVendor={onAddNewVendor}
            type={type ?? ExpenseElementType.Expense}
            onPOLineItemAssociation={onPOLineItemAssociation}
          />
        )}
      </>
    </DrawerLayout>
  );
};
