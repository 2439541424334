import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { tryFormatDate } from '../../../utils/TryFormatDate';
import Box from '@mui/material/Box';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import {
  IAccount,
  JournalEntryStatus,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Link from '@mui/material/Link';
import { NumericFormat } from 'react-number-format';
import { ReduxEntry } from '../../redux/statementTypes';
import { useLeases } from '../../../leases/redux/leasesSlice';
import { useTheme } from '@mui/material';
import { drawerChildIsOpenAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { useDispatch } from 'react-redux';

type JournalEntryDetailLineProps = {
  entry: ReduxEntry;
  selectedStatementId?: string;
  onOpenPopupStatement?: (entry: ReduxEntry, subAccountId?: string) => void;
  addColorIndicator?: boolean;
};

export const JournalEntryDetailLine: FC<JournalEntryDetailLineProps> = ({
  entry,
  selectedStatementId,
  onOpenPopupStatement,
  addColorIndicator,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeLease } = useLeases();
  const entryLinesWithId = entry?.lines?.map((line, index) => ({ ...line, id: index }));

  return (
    <Box
      sx={{
        border: addColorIndicator ? '.4rem solid' : undefined,
        borderColor: addColorIndicator ? theme.palette.secondary.main : 'inherit',
        borderRadius: 1,
        p: 1,
      }}
    >
      <Stack>
        <Typography>Date: {entry.date && tryFormatDate(entry.date)}</Typography>
        <Typography>Status: {entry.status}</Typography>
        <Box py={2} width={'100%'}>
          <Typography>Account Details:</Typography>
          <Box width={'100%'}>
            <StripedDataGrid
              hideFooter
              autoHeight={true}
              rows={entryLinesWithId}
              columns={[
                {
                  field: 'account',
                  headerName: 'Account',
                  flex: 3,
                  display: 'flex',
                  valueGetter: (v: IAccount) => v.name,
                  renderCell: (params) => {
                    return params.row.account.id === selectedStatementId && !activeLease.value ? (
                      params.value
                    ) : (
                      <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          onOpenPopupStatement?.(entry, params.row.account.id);
                          dispatch(drawerChildIsOpenAction(true));
                        }}
                      >
                        <Typography variant={'body2'}>{params.value}</Typography>
                      </Link>
                    );
                  },
                },
                { field: 'description', headerName: 'Description', flex: 3 },
                {
                  field: 'amount',
                  headerName: 'Amount',
                  flex: 1,
                  display: 'flex',
                  align: 'right',
                  headerAlign: 'right',
                  renderCell: (params) => {
                    return (
                      <Typography
                        variant={params.aggregation ? 'h6' : 'body2'}
                        fontStyle={entry.status === JournalEntryStatus.Pending ? 'italic' : 'normal'}
                      >
                        <NumericFormat
                          value={params.value}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={'$'}
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </Typography>
                    );
                  },
                },
              ]}
              getRowClassName={(params) => {
                return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
              }}
            />
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
