import { ButtonProps } from '@mui/material/Button';
import React, { FC, useRef } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

export type FileUploadButtonProps = Omit<ButtonProps, 'onClick'> & {
  onUpload: (file: File) => void;
  loading?: boolean;
  isInMenu?: boolean;
  isIcon?: boolean;
  accept?: string;
};

export const FileUploadButton: FC<FileUploadButtonProps> = ({
  isInMenu,
  loading,
  children,
  onUpload,
  isIcon,
  accept,
  ...buttonProps
}) => {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      onUpload(e.target.files[0]);
      if (fileUploadRef.current) fileUploadRef.current.value = '';
    }
  };

  if (isInMenu) {
    return (
      <MenuItem onClick={() => fileUploadRef?.current?.click()}>
        {loading ? (
          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <>
            {children}
            <input ref={fileUploadRef} type="file" hidden onChange={handleChange} />
          </>
        )}
      </MenuItem>
    );
  }
  if (isIcon) {
    return (
      <Tooltip title={'Add Attachment'}>
        <IconButton onClick={() => fileUploadRef?.current?.click()}>
          <input ref={fileUploadRef} type="file" accept={accept} hidden onChange={handleChange} />
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <LoadingButton
      variant="contained"
      color="primary"
      onClick={() => fileUploadRef?.current?.click()}
      {...buttonProps}
      loading={loading}
    >
      {children}
      <input ref={fileUploadRef} type="file" accept={accept} hidden onChange={handleChange} />
    </LoadingButton>
  );
};
