import React, { useCallback, useEffect, useState } from 'react';
import { StripedDataGrid } from '../../../datagrids/StripedDataGrid';
import {
  GridCellParams,
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowModesModel,
  GridRowOrderChangeParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { TaskStatusCell, TaskStatusEditCell } from './taskTableCells/TaskStatusCell';
import { CategoryCell, CategoryEditCell } from './taskTableCells/CategoryCell';
import { TaskNameCell } from './taskTableCells/TaskNameCell';
import { useDispatch } from 'react-redux';
import {
  getTaskGroupByAssetIdAction,
  getTaskGroupByCategoryOrAssigneeIdAction,
  getUngroupedTasksByAssetIdAction,
  updatePriorityAction,
  updateTaskAction,
  updateTaskListPaginationAction,
  updateTaskStatusAction,
  userAddedAsWatcherAction,
  useTasks,
  watchTaskAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { ClosedTaskStatuses, ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import {
  AssetType,
  AssociationChildType,
  CamTaskStatus,
  GetGroupedTasksHandlerTasksGroupBy,
  IGetUsersForAssetHandlerUserRoleInfo,
  ITaskCategory,
  ITaskDto,
  TaskSection,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { AssigneeCell, AssigneeEditCell } from './taskTableCells/AssigneeCell';
import EditIcon from '@mui/icons-material/Edit';
import { appTheme } from '../../../../../AppTheme';
import { AssetPathCell } from './taskTableCells/AssetPathCell';
import { ActionCell } from '../../../datagrids/ActionCell';
import { useIcons } from '../../../icons/useIcons';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { yellow } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Box from '@mui/material/Box';

type TaskTableProps = {
  parentRowId: string;
  includeClosed: boolean;
  propertyId?: string;
  associationType: AssetType;
  groupingType?: GetGroupedTasksHandlerTasksGroupBy;
  forceColor?: boolean;
  taskSection?: TaskSection;
};

export const TaskListTable = ({
  parentRowId,
  propertyId,
  associationType,
  groupingType,
  includeClosed,
  forceColor,
  taskSection,
}: TaskTableProps) => {
  const dispatch = useDispatch();

  const { selectedContext } = useAssets();
  const { currentUser } = useUser();
  const { getAssociationIcon, getActionIcon, ActionType } = useIcons();
  const { groupedTaskLists, ungroupedTaskList, groupingInfo, newTask, selectedTask } = useTasks();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(0);
  const [editRowsModel, setEditRowsModel] = useState<GridRowModesModel>({});
  const [rowCount, setRowCount] = useState<number>(0);
  const [groupedBy, setGroupedBy] = useState<GetGroupedTasksHandlerTasksGroupBy | undefined>();

  useEffect(() => {
    const connector = Connector.getExistingInstance();
    const handleUpdatedTask = (task: ITaskDto) => {
      dispatch(userAddedAsWatcherAction({ swapTask: task }));
    };

    connector.onReceiveUpdatedTask(handleUpdatedTask);
    return () => {
      connector.offReceiveUpdatedTask(handleUpdatedTask);
    };
  }, []);

  useEffect(() => {
    if (groupingType !== GetGroupedTasksHandlerTasksGroupBy.Asset && propertyId && groupingType) {
      dispatch(
        getTaskGroupByCategoryOrAssigneeIdAction({
          id: parentRowId,
          propertyId: propertyId,
          groupBy: groupingType,
          includeClosed: includeClosed,
          page,
          pageSize,
        }),
      );
    } else if (!groupingType) {
      dispatch(
        getUngroupedTasksByAssetIdAction({
          id: parentRowId,
          associationType: associationType,
          includeClosed: includeClosed,
          page,
          pageSize,
          taskSection,
        }),
      );
    } else {
      dispatch(
        getTaskGroupByAssetIdAction({
          associationId: parentRowId,
          associationType: associationType,
          includeClosed: includeClosed,
          page,
          pageSize,
          taskSection,
        }),
      );
    }
  }, [includeClosed, newTask.submitted, selectedTask.submitted]);

  useEffect(() => {
    setRowCount((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCount]);

  useEffect(() => {
    setGroupedBy(groupingInfo.groupedBy);
  }, [groupingInfo.groupedBy]);

  useEffect(() => {
    if (groupingInfo.isGrouped) {
      typeof groupedTaskLists[parentRowId] !== 'undefined' && setLoading(groupedTaskLists[parentRowId].loading);
      typeof groupedTaskLists[parentRowId] !== 'undefined' &&
        groupedTaskLists[parentRowId].value &&
        setRows(groupedTaskLists[parentRowId].value ?? []);
      typeof groupedTaskLists[parentRowId] !== 'undefined' &&
        setRowCount(groupedTaskLists[parentRowId].totalCount ?? 0);
    } else {
      ungroupedTaskList.value && setRows(ungroupedTaskList.value);
      setLoading(ungroupedTaskList.loading);
      setRowCount(ungroupedTaskList.totalCount ?? 0);
    }
  }, [ungroupedTaskList.value, groupedTaskLists]);

  const handleEditRowsModelChange = useCallback((model: GridRowModesModel) => {
    setEditRowsModel(model);
  }, []);

  const [rows, setRows] = useState<ReduxTask[]>([]);
  const [loading, setLoading] = useState(false);
  const [rowReordering, setRowReordering] = useState(false);

  useEffect(() => {
    if (parentRowId === '00000000-0000-0000-0000-000000000000') {
      setRowReordering(false);
    } else if (includeClosed && groupingInfo.isGrouped && groupedBy !== GetGroupedTasksHandlerTasksGroupBy.Asset) {
      setRowReordering(false);
    } else setRowReordering(true);
  }, [parentRowId, includeClosed, groupingInfo.isGrouped]);

  const findPriority = (
    groupingType: GetGroupedTasksHandlerTasksGroupBy | undefined,
    itemsAfterCurrentItem: ReduxTask[] | undefined,
  ) => {
    if (!itemsAfterCurrentItem || itemsAfterCurrentItem.length === 0) {
      if (!groupingType || groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset)
        return Math.max(...rows.map((r) => r.taskPriority ?? 0)) + 1;
      if (groupingType === GetGroupedTasksHandlerTasksGroupBy.Category)
        return Math.max(...rows.map((r) => r.categoryPriority ?? 0)) + 1;
      if (groupingType === GetGroupedTasksHandlerTasksGroupBy.Assignee)
        return Math.max(...rows.map((r) => r.assigneePriority ?? 0)) + 1;
      return 0;
    }
    if (!groupingType || groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset) {
      return (
        itemsAfterCurrentItem
          .filter((x) => x.taskPriority)
          .sort((a, b) => a.taskPriority! - b.taskPriority!)
          .map((x) => x.taskPriority)[0] ?? 1
      );
    } else if (groupingType === GetGroupedTasksHandlerTasksGroupBy.Category) {
      return (
        itemsAfterCurrentItem
          .filter((x) => x.categoryPriority)
          .sort((a, b) => a.categoryPriority! - b.categoryPriority!)
          .map((x) => x.categoryPriority)[0] ?? 1
      );
    } else if (groupingType === GetGroupedTasksHandlerTasksGroupBy.Assignee) {
      return (
        itemsAfterCurrentItem
          .filter((x) => x.assigneePriority)
          .sort((a, b) => a.assigneePriority! - b.assigneePriority!)
          .map((x) => x.assigneePriority)[0] ?? 1
      );
    }
  };

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const isAfter = params.targetIndex > params.oldIndex;
    const itemsAfterCurrentItem = rows.slice(params.targetIndex + (isAfter ? 1 : 0));
    const newPriority: number | undefined = findPriority(groupedBy, itemsAfterCurrentItem);
    if (!newPriority) {
      //do what?
    }
    newPriority &&
      dispatch(
        updatePriorityAction({
          id: params.row.id,
          groupingId: parentRowId,
          associatedId: !groupedBy
            ? parentRowId
            : groupedBy === GetGroupedTasksHandlerTasksGroupBy.Asset
              ? params.row.associatedId
              : params.row.propertyId,
          associationType: associationType,
          includeClosed: includeClosed,
          body: {
            targetPriority: newPriority,
          },
        }),
      );
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
    dispatch(
      updateTaskListPaginationAction({
        id: parentRowId,
        newPage: newPage,
        groupingType: groupedBy,
        associationType: associationType,
        includeClosed: includeClosed,
      }),
    );
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    dispatch(
      updateTaskListPaginationAction({
        id: parentRowId,
        newPageSize: newPageSize,
        groupingType: groupedBy,
        associationType: associationType,
        includeClosed: includeClosed,
      }),
    );
  };

  const handleCategoryChange = (value: ITaskCategory | null, taskId: string) => {
    const task = rows.find((row) => row.id === taskId);
    if (!task || !task.associatedId || !task.propertyId) return;
    dispatch(
      updateTaskAction({
        id: taskId,
        associatedId: !groupedBy
          ? parentRowId
          : groupedBy === GetGroupedTasksHandlerTasksGroupBy.Asset
            ? task.associatedId
            : task.propertyId,
        associationType: associationType,
        includeClosed: includeClosed,
        body: { ...task, category: value ?? undefined },
        parentRowId: parentRowId,
      }),
    );
  };

  const handleAssigneeChange = (newValue: IGetUsersForAssetHandlerUserRoleInfo | undefined, taskId: string) => {
    const task = rows.find((row) => row.id === taskId);
    if (!task || !task.associatedId || !task.propertyId) return;
    dispatch(
      updateTaskAction({
        id: taskId,
        associatedId:
          !groupingType || groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset
            ? task.associatedId
            : task.propertyId,
        associationType: associationType,
        includeClosed: includeClosed,
        body: {
          ...task,
          assignee: newValue === undefined ? undefined : { ...newValue },
        },
        parentRowId: parentRowId,
      }),
    );
  };

  function* createActions(params: GridRowParams<ReduxTask>) {
    const userId = currentUser.value?.id;
    if (params.row.assignee?.id !== userId) {
      yield (
        <ActionCell
          color={'default'}
          icon={getActionIcon(ActionType.Add)}
          key={'assignToMe'}
          label={'Assign To Me'}
          onClick={() => params.row.id && handleAssigneeChange(currentUser.value, params.row.id)}
          showInMenu
        />
      );
    }
    if (params.row.status && !ClosedTaskStatuses.includes(params.row.status)) {
      yield (
        <ActionCell
          color={'default'}
          icon={<CheckIcon />}
          key={'completeTask'}
          label={'Complete Task'}
          onClick={() =>
            params.row.id &&
            dispatch(
              updateTaskStatusAction({
                id: params.row.id,
                associationType: associationType,
                associatedId:
                  !groupingType || groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset
                    ? params.row.associatedId
                    : params.row.propertyId,
                includeClosed: includeClosed,
                parentRowId: parentRowId,
                body: { status: CamTaskStatus.Completed },
              }),
            )
          }
          showInMenu
        />
      );
    }
    if (params.row.watchers && params.row.watchers.some((w) => w.id === userId)) {
      yield (
        <ActionCell
          color={'warning'}
          icon={getAssociationIcon(AssociationChildType.Observation)}
          key={'removeWatcher'}
          label={'Unwatch Task'}
          onClick={() => {
            userId &&
              params.row.id &&
              dispatch(watchTaskAction({ taskId: params.row.id, user: currentUser.value ?? {}, watch: false }));
          }}
        />
      );
    } else {
      yield (
        <ActionCell
          color={'default'}
          icon={<VisibilityOffIcon />}
          key={'addWatcher'}
          label={'Watch Task'}
          onClick={() => {
            userId &&
              params.row.id &&
              dispatch(watchTaskAction({ taskId: params.row.id, user: currentUser.value ?? {}, watch: true }));
          }}
        />
      );
    }
  }

  const columns: GridColDef<ReduxTask>[] = [
    {
      field: 'name',
      type: 'string',
      headerName: 'Task Name',
      flex: 1.25,
      display: 'flex',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxTask, string>) => {
        return (
          <TaskNameCell {...params} forceColor={forceColor} groupingType={groupedBy} includeClosed={includeClosed} />
        );
      },
    },
    {
      field: 'description',
      type: 'string',
      headerName: 'Description',
      flex: 1.25,
      sortable: false,
      filterable: false,
    },
    {
      field: 'associatedName',
      type: 'string',
      headerName: 'Asset',
      flex: 2,
      display: 'flex',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxTask, string>) => {
        return <AssetPathCell {...params} />;
      },
    },
    {
      field: 'category',
      type: 'string',
      headerName: 'Category',
      flex: 1,
      display: 'flex',
      editable: true,
      sortable: false,
      filterable: false,
      renderHeader: () => {
        return (
          <>
            {'Category '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return <CategoryCell {...params} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <CategoryEditCell {...params} onChange={handleCategoryChange} />;
      },
    },
    {
      field: 'assignee',
      type: 'string',
      headerName: 'Assignee',
      flex: 1,
      display: 'flex',
      editable: true,
      sortable: false,
      filterable: false,
      renderHeader: () => {
        return (
          <>
            {'Assignee '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        return <AssigneeCell {...params} />;
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return <AssigneeEditCell {...params} onChange={handleAssigneeChange} />;
      },
    },
    {
      field: 'status',
      type: 'string',
      headerName: 'Status',
      editable: true,
      sortable: false,
      filterable: false,
      flex: 1,
      display: 'flex',
      renderHeader: () => {
        return (
          <>
            {'Status '}
            <EditIcon fontSize="small" color="disabled" />
          </>
        );
      },
      renderCell: (params: GridRenderCellParams) => {
        const normalizeDateTime = (dateTime: Date) => {
          return new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());
        };
        const currentDate = toStandardDate(selectedContext.currentDate ?? '');
        const taskDueDate =
          params.row.timeConfiguration?.endDateTime !== undefined &&
          normalizeDateTime(toStandardDate(params.row.timeConfiguration.endDateTime));

        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const differenceInMilliseconds = taskDueDate !== false && currentDate.getTime() - taskDueDate.getTime();

        const differenceInDays =
          differenceInMilliseconds !== false && Math.floor(differenceInMilliseconds / millisecondsPerDay);

        const isLate = differenceInDays !== false && differenceInDays > 0;
        const isDueSoon = differenceInDays !== false && (differenceInDays === -1 || differenceInDays === 0);
        const shouldShowTimeIcon = (isLate || isDueSoon) && params.row.timeConfiguration?.shouldNotifyIfLate;
        return (
          <Stack direction={'row'}>
            {shouldShowTimeIcon && (
              <Box sx={{ paddingRight: 1 }}>
                <Tooltip title={isLate ? 'Task Late' : 'Task Due Soon'} placement={'top'}>
                  <AccessTimeFilledIcon sx={{ color: isLate ? appTheme.palette.error.main : yellow[600] }} />
                </Tooltip>
              </Box>
            )}
            <TaskStatusCell {...params} />
          </Stack>
        );
      },
      renderEditCell: (params: GridRenderEditCellParams) => {
        return (
          <TaskStatusEditCell
            {...params}
            groupingType={groupedBy}
            parentRowId={parentRowId}
            includeClosed={includeClosed}
          />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      getActions: (params: GridRowParams) => Array.from(createActions(params)),
    },
  ];

  return (
    <Stack sx={{ py: 2, height: 1, boxSizing: 'border-box', backgroundColor: 'lightgray' }} direction="column">
      <Paper sx={{ flex: 1, mx: 'auto', width: '95%', p: 1 }}>
        <Stack
          direction="column"
          spacing={1}
          sx={{
            height: 1,
            '& .unprioritized': {
              backgroundColor: appTheme.palette.warning.main,
            },
            '& .unassigned': {
              backgroundColor: appTheme.palette.warning.light,
            },
          }}
        >
          <StripedDataGrid
            disableRowGrouping
            autoHeight
            rowHeight={75}
            rows={rows}
            loading={loading}
            rowReordering={rowReordering}
            onRowOrderChange={handleRowOrderChange}
            columns={columns}
            disableMultipleColumnsFiltering
            getRowClassName={(params) => {
              return params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
            }}
            pagination
            paginationMode={'server'}
            rowCount={rowCount}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{ page: page, pageSize: pageSize }}
            onPaginationModelChange={(model: GridPaginationModel) => {
              handlePageChange(model.page);
              handlePageSizeChange(model.pageSize);
            }}
            rowModesModel={editRowsModel}
            onRowModesModelChange={handleEditRowsModelChange}
            getCellClassName={(params: GridCellParams<ReduxTask>) => {
              if (parentRowId === '00000000-0000-0000-0000-000000000000' || params.field !== 'name') return '';
              if (
                !forceColor &&
                (params.row.status === CamTaskStatus.Abandoned ||
                  params.row.status === CamTaskStatus.Completed ||
                  params.row.status === CamTaskStatus.ConfirmedCompleted ||
                  params.row.status === CamTaskStatus.Archived)
              )
                return '';
              else {
                if (
                  (!groupingType && !params.row.taskPriority) ||
                  (groupingType === GetGroupedTasksHandlerTasksGroupBy.Asset && !params.row.taskPriority) ||
                  (groupingType === GetGroupedTasksHandlerTasksGroupBy.Category && !params.row.categoryPriority) ||
                  (groupingType === GetGroupedTasksHandlerTasksGroupBy.Assignee && !params.row.assigneePriority)
                ) {
                  return 'unprioritized';
                } else if (!params.row.assignee) {
                  return 'unassigned';
                } else return '';
              }
            }}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};
