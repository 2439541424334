import React, { FC, useEffect } from 'react';
import { StyledInfoBox } from './styledComponents/StyledInfoBox';
import FormControl from '@mui/material/FormControl';
import { Calendar } from './calendar/Calendar';
import { AssetTasks } from './tasks/components/AssetTasks';
import { ScheduledTasks } from './tasks/components/scheduledTasks/ScheduledTasks';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { TaskSection } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const Schedule: FC = () => {
  const [view, setView] = React.useState<'calendar' | 'list' | 'scheduled'>('calendar');
  const [title, setTitle] = React.useState<string>('');
  const [taskSection, setTaskSection] = React.useState<TaskSection>(TaskSection.Leasing);

  useEffect(() => {
    const taskSection = window.location.pathname.includes('leasing')
      ? TaskSection.Leasing
      : window.location.pathname.includes('operations')
        ? TaskSection.Operations
        : TaskSection.Financials;
    const title = window.location.pathname.includes('leasing')
      ? 'Leasing Planner'
      : window.location.pathname.includes('operations')
        ? 'Operations Planner'
        : 'Financials Planner';
    setTitle(title);
    setTaskSection(taskSection);
    setView('calendar');
  }, [window.location.pathname]);

  return (
    <StyledInfoBox label={title}>
      <FormControl>
        <ButtonGroup variant="outlined" aria-label="View button group">
          <Button
            sx={styles.button}
            size={'large'}
            value={'calendar'}
            onClick={() => setView('calendar')}
            variant={view === 'calendar' ? 'contained' : 'outlined'}
          >
            Calendar
          </Button>
          <Button
            size={'large'}
            sx={styles.button}
            onClick={() => setView('list')}
            variant={view === 'list' ? 'contained' : 'outlined'}
          >
            Tasks
          </Button>
          <Button
            size={'large'}
            sx={styles.button}
            onClick={() => setView('scheduled')}
            variant={view === 'scheduled' ? 'contained' : 'outlined'}
          >
            Scheduled
          </Button>
        </ButtonGroup>
      </FormControl>
      <Divider color={'darkgray'} sx={{ marginY: 1 }} />
      {view === 'calendar' ? (
        <Calendar taskSection={taskSection} />
      ) : view === 'list' ? (
        <AssetTasks taskSection={taskSection} />
      ) : (
        <ScheduledTasks taskSection={taskSection} />
      )}
    </StyledInfoBox>
  );
};

const styles = {
  button: {
    width: '8rem',
  },
};
