import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getNotificationsByAssetIdAction,
  newActivityArrivedAction,
  setAssetNotificationFiltersAction,
  setAssetNotificationSortAction,
  setPageNotificationsByAssetAction,
  setPageSizeNotificationsByAssetAction,
  updateNotificationAssociationStatusesFromTaskAction,
  useNotifications,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import Typography from '@mui/material/Typography';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import {
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import {
  mapReduxUserNotifications,
  notificationCreatedTimeAgo,
  notificationTypeMap,
  ReduxNotification,
} from '@monkeyjump-labs/cam-fe-shared/dist/types/notificationTypes';
import { AssetRouteType } from '@monkeyjump-labs/cam-fe-shared/dist/types/assetTypes';
import { StripedDataGrid, styledFilterPanelProps } from '../../../datagrids/StripedDataGrid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AssociationChildType,
  AssociationType,
  ITaskDto,
  NotificationType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { dateFilters, formatReduxFilters, stringFilters } from '../../../utils/filteringUtils';
import {
  ApplicationDialogTab,
  AssetTab,
  DetailsTab,
  ExpenseDialogTab,
  LeaseDialogTab,
  OverviewTab,
  TaskDialogTab,
} from '../../../tabs/TabTypeEnums';
import { AssetParams } from '../../../../../AppRouter';
import { AssociationCell } from '../../../datagrids/AssociationCell';
import Link from '@mui/material/Link';
import Connector from '@monkeyjump-labs/cam-fe-shared/dist/services/signalrConnection';
import { toStandardDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { addHours, format } from 'date-fns';
import { RenderGridCellExpand } from '../../../datagrids/RenderGridCellExpand';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import Tooltip from '@mui/material/Tooltip';
import { ToolbarWithAction } from '../../../datagrids/ToolbarWithAction';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';

type Props = {
  assetId: string;
  assetType: AssetRouteType;
};

export const AssetNotificationDataGrid = ({ assetId, assetType }: Props) => {
  const dispatch = useDispatch();
  const currentUser = useUser((u) => u.currentUser);
  const { selectedContext } = useAssets();
  const { id, innerTab, outerTab } = useParams<AssetParams>();
  const { assetNotifications } = useNotifications();
  const location = useLocation();
  const navigate = useNavigate();
  const highlightedNotificationId = new URLSearchParams(location.search).get('selected');
  const [includePersonalActivityLogs, setIncludePersonalActivityLogs] = React.useState(false);

  useEffect(() => {
    getNotifications();

    const connector = Connector.getExistingInstance();
    connector.onReceiveActivity((activity) => {
      dispatch(newActivityArrivedAction(mapReduxUserNotifications(activity)));
    });

    return () => {
      connector.offReceiveActivity();
    };
  }, [assetId]);

  useEffect(() => {
    getNotifications();
  }, [includePersonalActivityLogs]);

  useEffect(() => {
    const connector = Connector.getExistingInstance();

    const updateNotification = (task: ITaskDto) => {
      dispatch(updateNotificationAssociationStatusesFromTaskAction({ task: task }));
    };

    connector.onReceiveUpdatedTask(updateNotification);

    return () => {
      connector.offReceiveUpdatedTask(updateNotification);
    };
  }, [assetNotifications.value]);

  const getNotifications = () => {
    assetId &&
      dispatch(
        getNotificationsByAssetIdAction({
          assetId,
          assetType,
          startingNotificationId: highlightedNotificationId ?? undefined,
          currentUserId: includePersonalActivityLogs ? currentUser.value?.id : undefined,
        }),
      );
  };

  const handlePageChange = (page: number) => {
    dispatch(
      setPageNotificationsByAssetAction({
        assetId,
        assetType,
        page,
        currentUserId: includePersonalActivityLogs ? currentUser.value?.id : undefined,
      }),
    );
  };

  const handlePageSizeChange = (pageSize: number) => {
    dispatch(
      setPageSizeNotificationsByAssetAction({
        assetId,
        assetType,
        pageSize,
        currentUserId: includePersonalActivityLogs ? currentUser.value?.id : undefined,
      }),
    );
  };

  const handleTitleCellClick = (params: GridRenderCellParams<ReduxNotification>) => {
    if (!params.row.association) return;

    switch (params.row.association.associationType) {
      case AssociationType.RentalProperty:
        navigate(`/assets/property/${params.row.association.associatedId}/${AssetTab.Overview}/${OverviewTab.Metrics}`);
        break;
      case AssociationType.Building:
        navigate(`/assets/building/${params.row.association.associatedId}/${AssetTab.Overview}/${OverviewTab.Metrics}`);
        break;
      case AssociationType.BuildingUnit:
        navigate(`/assets/unit/${params.row.association.associatedId}/${AssetTab.Overview}/${OverviewTab.Metrics}`);
        break;
      case AssociationType.Application:
        let appTab = ApplicationDialogTab.Info;
        switch (params.row.association.associationChildType) {
          case AssociationChildType.Comment:
            appTab = ApplicationDialogTab.Comments;
            break;
          case AssociationChildType.BackgroundCheck:
            appTab = ApplicationDialogTab.BackgroundCheck;
            break;
        }
        const selectedChildId = params.row.association.associationChildNumber
          ? `?selected=${params.row.association.associationChildNumber}`
          : '';
        navigate(
          `/assets/${assetType}/${id}/${outerTab}/${innerTab}/application/${params.row.association.associatedId}/${appTab}${selectedChildId}`,
        );
        break;
      case AssociationType.Expense:
        let expenseTab = ExpenseDialogTab.Info;
        switch (params.row.association.associationChildType) {
          case AssociationChildType.Payment:
            expenseTab = ExpenseDialogTab.Payments;
            break;
          case AssociationChildType.Invoice:
            expenseTab = ExpenseDialogTab.Invoices;
            break;
          case AssociationChildType.Quote:
            expenseTab = ExpenseDialogTab.Quotes;
            break;
          case AssociationChildType.WorkOrder:
            expenseTab = ExpenseDialogTab.WorkOrders;
            break;
          case AssociationChildType.PurchaseOrder:
            expenseTab = ExpenseDialogTab.PurchaseOrders;
            break;
        }

        const openedChildItem = !params.row.association.associationChildNumber
          ? ''
          : '/' + params.row.association.associationChildNumber;
        navigate(
          `/assets/${assetType}/${id}/${outerTab}/${innerTab}/expense/${params.row.association.associatedId}/${expenseTab}${openedChildItem}`,
        );
        break;
      case AssociationType.Lease:
        let leaseTab = LeaseDialogTab.General;
        switch (params.row.association.associationChildType) {
          case AssociationChildType.Comment:
            leaseTab = LeaseDialogTab.Comments;
            break;
          case AssociationChildType.Document:
            leaseTab = LeaseDialogTab.Documents;
            break;
        }
        const selectedId = params.row.association.associationChildNumber
          ? `?selected=${params.row.association.associationChildNumber}`
          : '';
        navigate(
          `/assets/${assetType}/${id}/${outerTab}/${innerTab}/lease/${params.row.association.associatedId}/${leaseTab}${selectedId}`,
        );
        break;
      case AssociationType.Task:
        let tab = TaskDialogTab.Info;
        if (params.row.association?.associationChildType) {
          switch (params.row.association.associationChildType) {
            case AssociationChildType.Comment:
              tab = TaskDialogTab.Comments;
              break;
            case AssociationChildType.Document:
              tab = TaskDialogTab.Documents;
              break;
            case AssociationChildType.ChecklistItem:
              tab = TaskDialogTab.Description;
              break;
          }
        }

        const selectedIdString = params.row.association.associationChildNumber
          ? `?selected=${params.row.association.associationChildNumber}`
          : '';

        navigate(
          `/assets/${assetType}/${id}/${outerTab}/${innerTab}/task/${params.row.association.associatedId}/${tab}${selectedIdString}`,
        );

        break;
      case AssociationType.EmailThread:
        navigate(
          `/assets/${assetType}/${id}/${outerTab}/${innerTab}/emailThread/${params.row.association.associatedId}`,
        );
        break;
      case AssociationType.Detail:
        let detailsTab = DetailsTab.Info;
        if (params.row.association?.associationChildType) {
          switch (params.row.association.associationChildType) {
            case AssociationChildType.Observation:
              detailsTab = DetailsTab.Observations;
              break;
            case AssociationChildType.Document:
              detailsTab = DetailsTab.Documents;
              break;
          }
        }
        navigate(
          `/assets/${assetType}/${id}/${outerTab}/${innerTab}/detail/${params.row.association.associatedId}/${detailsTab}`,
        );

        break;
      default:
        params.row.unitId
          ? navigate(`/assets/unit/${params.row.unitId}/${AssetTab.Overview}/${OverviewTab.Metrics}`)
          : params.row.buildingId
            ? navigate(`/assets/building/${params.row.buildingId}/${AssetTab.Overview}/${OverviewTab.Metrics}`)
            : navigate(`/assets/property/${params.row.propertyId}/${AssetTab.Overview}/${OverviewTab.Notifications}`);
    }
  };

  const handleFilterModelChange = (model: GridFilterModel) => {
    if (model.items.length === 0) {
      dispatch(
        setAssetNotificationFiltersAction({
          assetType: assetType,
          assetId: assetId,
          startingNotificationId: highlightedNotificationId ?? undefined,
          filters: undefined,
        }),
      );
    } else {
      const filters = formatReduxFilters(model);
      dispatch(
        setAssetNotificationFiltersAction({
          assetType: assetType,
          assetId: assetId,
          startingNotificationId: highlightedNotificationId ?? undefined,
          filters,
        }),
      );
    }
  };

  const handleSortModelChange = (model: GridSortModel) => {
    if (model.length === 0) {
      dispatch(
        setAssetNotificationSortAction({
          assetType: assetType,
          assetId: assetId,
          startingNotificationId: highlightedNotificationId ?? undefined,
          sortDirection: undefined,
          sortBy: undefined,
        }),
      );
    } else {
      dispatch(
        setAssetNotificationSortAction({
          assetType: assetType,
          assetId: assetId,
          startingNotificationId: highlightedNotificationId ?? undefined,
          sortDirection: model[0].sort === 'asc' ? 'Ascending' : 'Descending',
          sortBy: model[0].field as keyof ReduxNotification,
        }),
      );
    }
  };

  const columns: GridColDef<ReduxNotification>[] = [
    {
      field: 'notificationType',
      headerName: 'Type',
      filterOperators: stringFilters,
      flex: 0.5,
      type: 'string',
      valueFormatter: (value: NotificationType) => {
        return notificationTypeMap.get(value);
      },
    },
    {
      field: 'title',
      headerName: 'Title',
      filterOperators: stringFilters,
      flex: 1,
      type: 'string',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ display: 'flex', height: '100%', flexGrow: 1, alignItems: 'center' }}>
          <Tooltip title={params.row.assetPath}>
            <Link sx={{ cursor: 'pointer' }} color="inherit">
              <Typography variant={'body2'} onClick={() => handleTitleCellClick(params)}>
                {params.value}
              </Typography>
            </Link>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      filterOperators: stringFilters,
      flex: 1,
      type: 'string',
      renderCell: (params: GridRenderCellParams<ReduxNotification, string>) => {
        const fullTextValue = params.row.detailedDescription ? `${params.row.detailedDescription}` : undefined;
        const updatedParams = { ...params, fullTextValue: fullTextValue };
        return <RenderGridCellExpand {...updatedParams} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      filterOperators: dateFilters,
      flex: 0.75,
      type: 'date',
      display: 'flex',
      valueGetter: (value?: string) => (value ? toStandardDate(value) : undefined),
      renderCell: (params: GridRenderCellParams<ReduxNotification, string>) => {
        const simulatedTimeDateWithNoTimestamp = toStandardDate(selectedContext.currentDate);
        const simulatedTimeAtSevenAM = simulatedTimeDateWithNoTimestamp
          ? addHours(simulatedTimeDateWithNoTimestamp, 7)
          : undefined;
        const currentDate = selectedContext.isSimulated && simulatedTimeAtSevenAM ? simulatedTimeAtSevenAM : new Date();
        const value = params.value && notificationCreatedTimeAgo(toStandardDate(params.value), currentDate);
        return (
          <Tooltip title={params.value ? format(toStandardDate(params.value), 'h:mm a, MMMM dd') : ''}>
            <Typography variant={'body2'}>{value}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'associations',
      headerName: 'Associations',
      headerAlign: 'center',
      align: 'left',
      flex: 0.5,
      type: 'string',
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ReduxNotification>) => {
        return <AssociationCell associations={params.row.associations ?? []} tableType={'notifications'} />;
      },
    },
  ];

  if (assetNotifications.value && assetNotifications.value) {
    return (
      <StyledInfoBox label={'Activity Log'}>
        <Box my={1}>
          <StripedDataGrid
            disableRowGrouping
            autoHeight
            initialState={{ sorting: { sortModel: [{ field: 'createdAt', sort: 'desc' }] } }}
            columns={columns}
            loading={assetNotifications.loading}
            rows={assetNotifications.value}
            slots={{ toolbar: ToolbarWithAction }}
            slotProps={{
              toolbar: {
                action: (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={includePersonalActivityLogs}
                          onChange={(e) => {
                            setIncludePersonalActivityLogs(e.target.checked);
                          }}
                        />
                      }
                      label={'My Activity'}
                    />
                  </FormGroup>
                ),
                title: 'My Activity',
              },
              filterPanel: styledFilterPanelProps,
            }}
            pagination
            paginationMode={'server'}
            pageSizeOptions={[10, 25, 50, 100]}
            paginationModel={{
              page: assetNotifications.page ?? 0,
              pageSize: assetNotifications.pageSize ?? 10,
            }}
            onPaginationModelChange={(model: GridPaginationModel) => {
              handlePageChange(model.page);
              handlePageSizeChange(model.pageSize);
            }}
            onFilterModelChange={handleFilterModelChange}
            filterMode={'server'}
            sortingMode={'server'}
            onSortModelChange={handleSortModelChange}
            rowCount={assetNotifications.totalCount ?? 0}
            getRowClassName={(params) => {
              let classNames = params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
              if (highlightedNotificationId && params.id == highlightedNotificationId) {
                classNames += ' highlighted';
              }

              return classNames;
            }}
          />
        </Box>
      </StyledInfoBox>
    );
  }

  return (
    <StyledInfoBox label={'Activity Log'}>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="h5" fontStyle="italic">
          No logs found for this property
        </Typography>
      </Box>
    </StyledInfoBox>
  );
};
