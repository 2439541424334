import React, { FC } from 'react';
import List from '@mui/material/List';
import { FileUploadButton } from '../../buttons/FileUploadButton';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { DocumentLink } from './DocumentLink';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ICamAssociation } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export type DocumentListProps = {
  propertyId?: string;
  association: ICamAssociation;
  onUpload?: (file: File) => any;
  onDeleteDocument?: (id: string) => void;
  value: ReduxDocument[];
  uploading?: boolean;
  showAsIcons?: boolean;
};

export const imageExtensionTypes = ['jpg', 'jpeg', 'png', 'gif'];

export const DocumentList: FC<DocumentListProps> = ({
  propertyId,
  association,
  value,
  onUpload,
  onDeleteDocument,
  showAsIcons,
  uploading,
}) => {
  return (
    <>
      {showAsIcons ? (
        <>
          <Stack flexDirection={'row'}>
            {onUpload && (
              <FileUploadButton onUpload={onUpload} loading={uploading} isIcon={true}>
                Upload File
              </FileUploadButton>
            )}
            {value.map((doc) => (
              <DocumentLink
                propertyId={propertyId}
                key={doc.id ?? doc.uri}
                association={association}
                document={doc}
                showAsIcons={showAsIcons}
              />
            ))}
          </Stack>
        </>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            {onUpload && (
              <FileUploadButton onUpload={onUpload} loading={uploading}>
                Upload File
              </FileUploadButton>
            )}
            <List sx={{ width: '100%' }}>
              {value.map((doc) => (
                <DocumentLink
                  propertyId={propertyId}
                  key={doc.id ?? doc.uri}
                  association={association}
                  document={doc}
                  showAsIcons={showAsIcons}
                  onDeleteDocument={onDeleteDocument}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      )}
    </>
  );
};
