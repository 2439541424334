import React, { FC } from 'react';
import { Checklist } from '../../../../../../_shared/checklists/components/Checklist';
import {
  addChecklistItemAction,
  addCommentToSopTaskChecklistItemAction,
  addDocumentToSopTaskChecklistItemAction,
  deleteChecklistItemAction,
  removeDocumentFromSopTaskChecklistItemAction,
  reorderChecklistAction,
  updateSopTaskChecklistItemAction,
  useSopTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { useDispatch } from 'react-redux';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';

export const SopTaskChecklist: FC = () => {
  const { selectedSopTask } = useSopTasks();
  const dispatch = useDispatch();
  const propertyId = selectedSopTask.value?.propertyId;
  const handleDeleteItem = (id?: string) => {
    if (!id || !selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      deleteChecklistItemAction({
        configId: selectedSopTask.value?.id,
        propertyId: propertyId,
        itemId: id,
      }),
    );
  };

  const handleAddItem = (value: string) => {
    if (!selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      addChecklistItemAction({
        configId: selectedSopTask.value?.id,
        propertyId: propertyId,
        itemName: value,
      }),
    );
  };

  const handleReorderChecklist = (ids: string[]) => {
    if (!selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      reorderChecklistAction({
        configId: selectedSopTask.value.id,
        propertyId: propertyId,
        itemIds: ids,
      }),
    );
  };
  const handleAddComment = (comment: string, itemId: string) => {
    if (!selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      addCommentToSopTaskChecklistItemAction({
        propertyId: propertyId,
        configId: selectedSopTask.value.id,
        itemId: itemId,
        comment: comment,
      }),
    );
  };

  const handleUploadDocument = (file: File, itemId: string) => {
    if (!selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      addDocumentToSopTaskChecklistItemAction({
        propertyId: propertyId,
        itemId: itemId,
        configId: selectedSopTask.value.id,
        file: file,
      }),
    );
  };

  const handleDeleteDocument = (docId: string, itemId: string) => {
    if (!selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      removeDocumentFromSopTaskChecklistItemAction({
        propertyId: propertyId,
        itemId: itemId,
        docId: docId,
        configId: selectedSopTask.value.id,
      }),
    );
  };

  const handleUpdateChecklistItem = (itemId: string, newName?: string, newDescription?: string) => {
    if (!selectedSopTask.value?.id || !propertyId) return;
    dispatch(
      updateSopTaskChecklistItemAction({
        propertyId: propertyId,
        configId: selectedSopTask.value.id,
        itemId: itemId,
        body: { name: newName, description: newDescription },
      }),
    );
  };

  return (
    <Checklist
      propertyId={selectedSopTask.value?.propertyId}
      submitting={selectedSopTask.submitting}
      checklist={selectedSopTask.value?.checklist}
      onAddItem={handleAddItem}
      onDeleteItem={handleDeleteItem}
      onReorderChecklist={handleReorderChecklist}
      associatedId={selectedSopTask.value?.id}
      associationType={AssociationType.TaskConfiguration}
      onAddComment={handleAddComment}
      onUploadDocument={handleUploadDocument}
      onDeleteDocument={handleDeleteDocument}
      onUpdateChecklistItem={handleUpdateChecklistItem}
    />
  );
};
