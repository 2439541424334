import { AssetTab, FinancialsTab, InfoTab, LeasingTab, OperationsTab, OverviewTab } from './TabTypeEnums';
import PaidIcon from '@mui/icons-material/Paid';
import { InformationTab } from './InformationTab';
import SettingsIcon from '@mui/icons-material/Settings';
import { ConfigurationTab } from './ConfigurationTab';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import SendTimeExtensionIcon from '@mui/icons-material/SendTimeExtension';
import { AssetSetupTab } from './AssetSetupTab';
import PersonIcon from '@mui/icons-material/Person';
import Stack from '@mui/material/Stack';
import { UserAccessTable } from '../../property/components/propertyTabs/propertyInfoTabs/userAccess/UserAccessTable';
import { InvitationTable } from '../../property/components/propertyTabs/propertyInfoTabs/userAccess/InvitationTable';
import { SopTaskTemplates } from '../../property/components/propertyTabs/propertyInfoTabs/sopTaskTemplates/components/SopTaskTemplates';
import CategoryIcon from '@mui/icons-material/Category';
import { Categories } from '../../property/components/propertyTabs/propertyInfoTabs/categories/Categories';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaymentIcon from '@mui/icons-material/Payment';
import { SoftwareBilling } from '../../property/components/financials/components/billing/SoftwareBilling';
import { Feature } from '../../global/Feature';
import { Details } from '../details/components/Details';
import InsightsIcon from '@mui/icons-material/Insights';
import { Metrics } from '../../property/components/propertyTabs/propertyOverviewTabs/metrics/components/Metrics';
import ListIcon from '@mui/icons-material/List';
import { ToDoList } from '../tasks/components/ToDoList';
import { AssetNotificationsTab } from '../notifications/components/AssetNotificationsTab';
import { Emails } from '../communications/components/Emails';
import { SmsView } from '../communications/components/SmsView';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Expenses } from '../expenses/components/Expenses';
import { CurrentLeasesTab } from '../leases/components/CurrentLeasesTab';
import { LeasingTables } from '../../property/components/propertyTabs/propertyLeasingTabs/LeasingTables';
import { ApplicationsTab } from '../../property/components/propertyTabs/propertyLeasingTabs/ApplicationsTab';
import CampaignIcon from '@mui/icons-material/Campaign';
import { AssetType, AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import React from 'react';
import { VendorManagement } from '../../property/components/propertyTabs/propertyInfoTabs/vendorManagement/VendorManagement';
import { MenuBook } from '@mui/icons-material';
import { IncomeTab } from '../statements/components/income/IncomeTab';
import { ChartOfAccounts } from '../../property/components/financials/chartOfAccounts/ChartOfAccounts';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Reports } from '../../property/components/financials/components/reports/Reports';
import { useIcons } from '../icons/useIcons';
import { ExternalAccounts } from '../../property/components/financials/components/externalAccounts/ExternalAccounts';
import { UnitTemplateManagement } from '../unitTemplates/components/UnitTemplateManagement';
import { AdvertiseListings } from '../../listings/components/assetView/AdvertiseListings';
import { EmailTemplateManagement } from '../emailTemplates/components/EmailTemplateManagement';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Calendar } from '../calendar/Calendar';
import { Schedule } from '../Schedule';
import FactCheckIcon from '@mui/icons-material/FactCheck';

interface TabIconConfig {
  label: string;
  icon: JSX.Element;
  component: JSX.Element;
}

export const useTabConfig = () => {
  const { getAssociationIcon, getTabIcon } = useIcons();
  const iconConfigs: Record<InfoTab | LeasingTab | OverviewTab | OperationsTab | FinancialsTab, TabIconConfig> = {
    [InfoTab.Information]: {
      label: 'Information',
      icon: getTabIcon('info'),
      component: <InformationTab />,
    },
    [InfoTab.Configuration]: {
      label: 'Settings',
      icon: <SettingsIcon />,
      component: <ConfigurationTab />,
    },
    [InfoTab.Setup]: { label: 'Asset Setup', icon: <MicrowaveIcon />, component: <AssetSetupTab /> },
    [InfoTab.UserAccess]: {
      label: 'User Access',
      icon: <PersonIcon />,
      component: (
        <Stack spacing={3}>
          <UserAccessTable />
          <InvitationTable />
        </Stack>
      ),
    },
    [InfoTab.SopDefinitions]: {
      label: 'SOP Templates',
      icon: getAssociationIcon(AssociationType.TaskConfiguration),
      component: <SopTaskTemplates />,
    },
    [InfoTab.TaskCategories]: { label: 'Task Categories', icon: <CategoryIcon />, component: <Categories /> },
    [InfoTab.VendorManagement]: {
      label: 'Vendor Management',
      icon: getAssociationIcon(AssociationType.Vendor),
      component: <VendorManagement />,
    },
    [InfoTab.UnitTemplates]: {
      label: 'Unit Templates',
      icon: getAssociationIcon(AssociationType.UnitTemplate),
      component: <UnitTemplateManagement />,
    },
    [InfoTab.EmailTemplates]: {
      label: 'Email Templates',
      icon: <SendTimeExtensionIcon />,
      component: <EmailTemplateManagement />,
    },
    [OverviewTab.Metrics]: { label: 'Metrics', icon: <InsightsIcon />, component: <Metrics /> },
    [OverviewTab.ToDo]: { label: 'To-Do List', icon: <ListIcon />, component: <ToDoList /> },
    [OverviewTab.Notifications]: {
      label: 'Activity',
      icon: getAssociationIcon(AssociationType.Notification),
      component: <AssetNotificationsTab />,
    },
    [OverviewTab.Communications]: {
      label: 'Communications',
      icon: getAssociationIcon(AssociationType.EmailThread),
      component: (
        <>
          <Emails />
          <SmsView />
        </>
      ),
    },
    [OverviewTab.Calendar]: { label: 'Calendar', icon: <CalendarMonthIcon />, component: <Calendar /> },
    [OperationsTab.Planner]: {
      label: 'Schedule',
      icon: <FactCheckIcon />,
      component: <Schedule />,
    },
    [FinancialsTab.Expenses]: {
      label: 'Expenses',
      icon: getAssociationIcon(AssociationType.Expense),
      component: <Expenses />,
    },
    [FinancialsTab.Income]: { label: 'Income', icon: <PaidIcon />, component: <IncomeTab /> },
    [OperationsTab.Details]: {
      label: 'Details',
      icon: getAssociationIcon(AssociationType.Detail),
      component: (
        <Feature flag={'Feature.Unit.UnitDetails'}>
          <Details />
        </Feature>
      ),
    },
    [LeasingTab.CurrentLeases]: {
      label: 'Current Leases',
      icon: getAssociationIcon(AssociationType.Lease),
      component: <CurrentLeasesTab />,
    },
    [LeasingTab.Leasing]: { label: 'Leasing', icon: <ContentPasteSearchIcon />, component: <LeasingTables /> },
    [LeasingTab.Applications]: {
      label: 'Applications & Vacancy',
      icon: getAssociationIcon(AssociationType.Application),
      component: (
        <Feature flag="Feature.Property.VacantUnits">
          <ApplicationsTab />
        </Feature>
      ),
    },
    [LeasingTab.Advertise]: { label: 'Advertise', icon: <CampaignIcon />, component: <AdvertiseListings /> },
    [FinancialsTab.Journal]: { label: 'Chart of Accounts', icon: <MenuBook />, component: <ChartOfAccounts /> },
    [FinancialsTab.Accounts]: {
      label: 'External Accounts',
      icon: <AccountBalanceIcon />,
      component: <ExternalAccounts />,
    },
    [FinancialsTab.Billing]: { label: 'Software Billing', icon: <PaymentIcon />, component: <SoftwareBilling /> },
    [FinancialsTab.Reports]: { label: 'Reports', icon: <AssessmentIcon />, component: <Reports /> },
  };

  const overviewTabConfigs: Record<AssetType, OverviewTab[]> = {
    [AssetType.RentalProperty]: [
      OverviewTab.Metrics,
      OverviewTab.Calendar,
      OverviewTab.ToDo,
      OverviewTab.Notifications,
      OverviewTab.Communications,
    ],
    [AssetType.Building]: [OverviewTab.Notifications],
    [AssetType.BuildingUnit]: [OverviewTab.Notifications],
    [AssetType.Room]: [],
  };

  const leasingTabConfigs: Record<AssetType, LeasingTab[]> = {
    [AssetType.RentalProperty]: [
      LeasingTab.CurrentLeases,
      LeasingTab.Leasing,
      LeasingTab.Applications,
      LeasingTab.Advertise,
      LeasingTab.Planner,
    ],
    [AssetType.Building]: [LeasingTab.CurrentLeases, LeasingTab.Advertise, LeasingTab.Planner],
    [AssetType.BuildingUnit]: [LeasingTab.CurrentLeases, LeasingTab.Advertise, LeasingTab.Planner],
    [AssetType.Room]: [],
  };

  const operationsTabConfigs: Record<AssetType, OperationsTab[]> = {
    [AssetType.RentalProperty]: [OperationsTab.Planner, OperationsTab.Details],
    [AssetType.Building]: [OperationsTab.Planner, OperationsTab.Details],
    [AssetType.BuildingUnit]: [OperationsTab.Planner, OperationsTab.Details],
    [AssetType.Room]: [],
  };

  const infoTabConfigs: Record<AssetType, InfoTab[]> = {
    [AssetType.RentalProperty]: [
      InfoTab.Information,
      InfoTab.Configuration,
      InfoTab.UserAccess,
      InfoTab.VendorManagement,
      InfoTab.TaskCategories,
      InfoTab.EmailTemplates,
      InfoTab.SopDefinitions,
      InfoTab.UnitTemplates,
    ],
    [AssetType.Building]: [InfoTab.Information, InfoTab.Configuration, InfoTab.UnitTemplates],
    [AssetType.BuildingUnit]: [InfoTab.Information, InfoTab.Configuration],
    [AssetType.Room]: [],
  };

  const financialsTabConfigs: Record<AssetType, FinancialsTab[]> = {
    [AssetType.RentalProperty]: [
      FinancialsTab.Journal,
      FinancialsTab.Accounts,
      FinancialsTab.Billing,
      FinancialsTab.Reports,
      FinancialsTab.Expenses,
      FinancialsTab.Income,
      FinancialsTab.Planner,
    ],
    [AssetType.Building]: [FinancialsTab.Expenses, FinancialsTab.Income, FinancialsTab.Planner],
    [AssetType.BuildingUnit]: [FinancialsTab.Expenses, FinancialsTab.Income, FinancialsTab.Planner],
    [AssetType.Room]: [],
  };

  const findDefaultTab = (assetType: AssetType, tab: AssetTab) => {
    return tab === AssetTab.Info
      ? InfoTab.Information
      : tab === AssetTab.Operations
        ? OperationsTab.Planner
        : tab === AssetTab.Leasing
          ? LeasingTab.CurrentLeases
          : tab === AssetTab.Financials
            ? assetType === AssetType.RentalProperty
              ? FinancialsTab.Journal
              : FinancialsTab.Expenses
            : assetType === AssetType.RentalProperty
              ? OverviewTab.Metrics
              : OverviewTab.Notifications;
  };

  const findCurrentTabConfigs = (tab: AssetTab) => {
    return tab === AssetTab.Operations
      ? operationsTabConfigs
      : tab === AssetTab.Leasing
        ? leasingTabConfigs
        : tab === AssetTab.Overview
          ? overviewTabConfigs
          : tab === AssetTab.Financials
            ? financialsTabConfigs
            : infoTabConfigs;
  };

  const getTabLabel = (tab: LeasingTab | OperationsTab | OverviewTab | InfoTab | FinancialsTab) => {
    return iconConfigs[tab]?.label;
  };

  return {
    iconConfigs,
    infoTabConfigs,
    operationsTabConfigs,
    leasingTabConfigs,
    overviewTabConfigs,
    financialsTabConfigs,
    findDefaultTab,
    findCurrentTabConfigs,
    getTabLabel,
  };
};
