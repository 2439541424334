import React, { FC, useEffect, useState } from 'react';
import { ListingInfo } from './ListingInfo';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import { AgentInfo } from './AgentInfo';
import { AvailableListing } from '../../webView/AvailableListing';
import { ListingTab } from '../../../../_shared/tabs/TabTypeEnums';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  addPhotoAction,
  removePhotoAction,
  reorderPhotoAction,
  updateListingAction,
  useListings,
} from '../../../redux/listingSlice';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import { ListingStatusBar } from '../../shared/ListingStatusBar';
import { ReorderableDocumentList } from '../../../../_shared/documents/components/ReorderableDocumentList';
import Typography from '@mui/material/Typography';
import { ImageCropperDialog } from '../../../../_shared/documents/components/ImageCropperDialog';
import { ReduxListing } from '../../../redux/listingData';

type ListingTabsProps = {
  tab?: string;
};

export const ListingTabs: FC<ListingTabsProps> = ({ tab }) => {
  const dispatch = useDispatch();
  const { selectedListing } = useListings();
  const [listing, setListing] = useState<ReduxListing | undefined>(undefined);
  const [openImageCropper, setOpenImageCropper] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<File>();

  useEffect(() => {
    setListing(selectedListing.value);
  }, [selectedListing.value]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    listing && listing.id && dispatch(updateListingAction({ id: listing.id, body: listing }));
  };

  const handleSetListing = (field: keyof ReduxListing, value: any) => {
    setListing({ ...listing, [field]: value });
  };

  const handleReorderPhotos = (documentIds: string[]) => {
    listing?.id && dispatch(reorderPhotoAction({ id: listing?.id ?? '', photoIds: documentIds }));
  };

  const handleCloseImageCropper = () => {
    setSelectedPhoto(undefined);
    setOpenImageCropper(false);
  };

  const handleAddPhoto = (file: File) => {
    setSelectedPhoto(file);
    setOpenImageCropper(true);
  };

  const handleUploadPhoto = (file: File) => {
    listing?.id && dispatch(addPhotoAction({ id: listing?.id, photo: file }));
    handleCloseImageCropper();
  };

  const handleRemovePhoto = (documentId: string) => {
    listing?.id && dispatch(removePhotoAction({ id: listing?.id, photoId: documentId }));
  };

  return (
    <Box sx={{ width: '100%', m: 1 }}>
      <form onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Stack direction={'row'}>
            {tab === ListingTab.Info || tab === ListingTab.Agent ? (
              <Box sx={{ p: 1 }}>
                <LoadingButton
                  type={'submit'}
                  variant={'contained'}
                  loading={selectedListing.submitting}
                  disabled={selectedListing.value === listing}
                  sx={{ mr: 1 }}
                >
                  Save
                </LoadingButton>
              </Box>
            ) : null}
            {listing && <ListingStatusBar listing={listing} />}
          </Stack>
          {
            {
              info: <ListingInfo listing={listing} onChange={handleSetListing} />,
              photos: (
                <StyledInfoBox label={'Photo Gallery'}>
                  <Typography fontStyle={'italic'} variant={'body2'}>
                    Five or more photos are recommended.
                  </Typography>
                  <ReorderableDocumentList
                    documents={listing?.photos ?? []}
                    onReorderDocuments={handleReorderPhotos}
                    onAddDocument={handleAddPhoto}
                    onRemoveDocument={handleRemovePhoto}
                    loading={selectedListing.submitting}
                    listingId={listing?.id}
                  />
                </StyledInfoBox>
              ),
              agent: <AgentInfo listing={listing} onChange={handleSetListing} />,
              webView: <AvailableListing isPropertyView />,
            }[tab ?? 'info']
          }
        </Stack>
        <ImageCropperDialog
          open={openImageCropper}
          onClose={handleCloseImageCropper}
          onUpload={handleUploadPhoto}
          selectedFile={selectedPhoto}
        />
      </form>
    </Box>
  );
};
