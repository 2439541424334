import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import {
  deletePropertyAction,
  restorePropertyAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import {
  deleteBuildingAction,
  restoreBuildingAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/buildingSlice';
import { deleteUnitAction, restoreUnitAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/unitSlice';
import { deleteRoomAction } from '../../../../../room/redux/roomSlice';
import { useNavigate } from 'react-router-dom';
import { AssetTab, InfoTab, LeasingTab, OperationsTab, OverviewTab } from '../../../../../_shared/tabs/TabTypeEnums';

type Props = {
  assetName: string;
  itemId: string;
  hover: boolean;
  onOpen: () => void;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  isSimulated: boolean;
  isArchived: boolean;
  isCamProperty: boolean;
};

export const TreeItemPopupMenu = ({
  onOpen,
  assetName,
  itemId,
  hover,
  onMenuClose,
  onMenuOpen,
  isSimulated,
  isArchived,
  isCamProperty,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onMenuOpen();
  };

  const navigateToTab = (tab: AssetTab) => {
    switch (tab) {
      case AssetTab.Overview:
        navigate(`/assets/${assetName}/${itemId}/${tab}/${OverviewTab.Metrics}`);
        break;
      case AssetTab.Leasing:
        navigate(`/assets/${assetName}/${itemId}/${tab}/${LeasingTab.CurrentLeases}`);
        break;
      case AssetTab.Operations:
        navigate(`/assets/${assetName}/${itemId}/${tab}/${OperationsTab.Planner}`);
        break;
      case AssetTab.Info:
        navigate(`/assets/${assetName}/${itemId}/${tab}/${InfoTab.Information}`);
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onMenuClose();
  };

  const addAsset = () => {
    onOpen();
    setAnchorEl(null);
  };

  const deleteAsset = () => {
    switch (assetName) {
      case 'property':
        dispatch(deletePropertyAction(itemId));
        break;
      case 'building':
        dispatch(deleteBuildingAction(itemId));
        break;
      case 'unit':
        dispatch(deleteUnitAction(itemId));
        break;
      case 'room':
        dispatch(deleteRoomAction(itemId));
    }
  };

  const restoreAsset = () => {
    switch (assetName) {
      case 'property':
        dispatch(restorePropertyAction(itemId));
        break;
      case 'building':
        dispatch(restoreBuildingAction(itemId));
        break;
      case 'unit':
        dispatch(restoreUnitAction(itemId));
        break;
      case 'room':
        break;
    }
  };

  const handleGoToWebListings = () => {
    navigate(`/listings/${itemId}`);
    handleClose();
  };

  if (assetName === 'room' || isCamProperty) {
    return <Box sx={{ width: '1rem', marginRight: '1rem' }}></Box>;
  } else {
    return (
      <Box sx={{ width: '1rem', marginRight: '1rem' }}>
        {hover ? (
          <div>
            <IconButton
              id="positioned-menu-button"
              aria-controls={open ? 'positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="positioned-menu"
              aria-labelledby="positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={() => navigateToTab(AssetTab.Overview)}>Coordination</MenuItem>
              <MenuItem onClick={() => navigateToTab(AssetTab.Leasing)}> Leasing</MenuItem>
              <MenuItem onClick={() => navigateToTab(AssetTab.Operations)}>Operations</MenuItem>
              <MenuItem onClick={() => navigateToTab(AssetTab.Info)}>Configuration</MenuItem>
              {assetName === 'property' && <MenuItem onClick={handleGoToWebListings}>Go To Listings</MenuItem>}
              {!isArchived &&
                {
                  property: <MenuItem onClick={addAsset}>Add Building</MenuItem>,
                  building: <MenuItem onClick={addAsset}>Add Unit</MenuItem>,
                  unit: <MenuItem onClick={addAsset}>Add Room</MenuItem>,
                }[assetName]}
              {!isArchived ? (
                <MenuItem onClick={deleteAsset}>
                  {isSimulated
                    ? `Delete ${assetName.charAt(0).toUpperCase() + assetName.slice(1)}`
                    : `Archive ${assetName.charAt(0).toUpperCase() + assetName.slice(1)}`}
                </MenuItem>
              ) : (
                <MenuItem onClick={restoreAsset}>
                  Restore {assetName.charAt(0).toUpperCase() + assetName.slice(1)}
                </MenuItem>
              )}
            </Menu>
          </div>
        ) : (
          <Box sx={{ width: '1rem', marginRight: '1rem' }}></Box>
        )}
      </Box>
    );
  }
};
