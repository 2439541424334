import React, { CSSProperties, FC, FormEvent, useEffect, useRef, useState } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Box from '@mui/material/Box';
import { imageExtensionTypes } from './DocumentList';
import Typography from '@mui/material/Typography';
import { getFileExtension } from '../../utils/getFileExtension';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { tryFormatDate } from '../../utils/TryFormatDate';
import LoadingButton from '@mui/lab/LoadingButton';
import { updateDocumentMetadataAction, useDocuments } from '../redux/documentSlice';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import {
  AssociationChildType,
  AssociationType,
  ICamAssociation,
  SimpleDocumentType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { PdfViewer } from './PdfViewer';
import Button from '@mui/material/Button';
import { ImageViewer } from './ImageViewer';
import { drawerChildIsOpenAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';

type DocumentDisplayComponentProps = {
  document: ReduxDocument | undefined;
  association: ICamAssociation;
};

export const DocumentInformation: FC<DocumentDisplayComponentProps> = ({ document, association }) => {
  const docType = getFileExtension(document?.name);
  const dispatch = useDispatch();
  const { selectedDocument } = useDocuments();
  const { selectedContext } = useAssets();
  const [editingMetadata, setEditingMetadata] = useState(document?.metadata);
  const [dirty, setDirty] = useState(false);
  const theme = useTheme();
  const [containerWidth, setContainerWidth] = useState(0);
  const containerRef = useRef(null);
  const [openPdfViewer, setOpenPdfViewer] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setContainerWidth(entry.contentRect.width);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const stackDirection = containerWidth < 600 ? 'column' : 'row';
  const imgStyle: CSSProperties = {
    objectFit: 'cover',
    cursor: 'zoom-in',
    maxWidth: containerWidth < 600 ? 350 : 550,
    maxHeight: containerWidth < 600 ? 250 : 350,
  };
  const containerStyle: CSSProperties = {
    maxWidth: containerWidth < 600 ? 350 : 550,
    maxHeight: containerWidth < 600 ? 250 : 350,
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  };

  useEffect(() => {
    selectedDocument.submitted && setDirty(false);
  }, [selectedDocument.submitted]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!selectedContext.propertyId || !association.associatedId || !association.associationType || !document?.id)
      return;
    const associationType =
      association.associationChildType === AssociationChildType.Observation
        ? AssociationType.DetailObservation
        : association.associationType;
    const childType =
      association.associationChildType === AssociationChildType.Observation
        ? undefined
        : association.associationChildType;
    const childNumber =
      association.associationChildType === AssociationChildType.Observation
        ? undefined
        : association.associationChildNumber;
    dispatch(
      updateDocumentMetadataAction({
        propertyId: selectedContext.propertyId,
        associationType: associationType,
        associationId: association.associatedId,
        childAssociationType: childType,
        childAssociationId: childNumber,
        documentId: document?.id,
        body: {
          title: editingMetadata?.title,
          description: editingMetadata?.description,
        },
      }),
    );
  };

  if (!document) return <></>;

  return (
    <Stack direction={stackDirection} spacing={1} ref={containerRef}>
      {document.metadata?.documentType === SimpleDocumentType.Image && (
        <Box
          border={'.3rem solid'}
          borderColor={theme.palette.primary.main}
          sx={containerStyle}
          onClick={() => {
            setOpenImageViewer(true);
            dispatch(drawerChildIsOpenAction(true));
          }}
        >
          <img src={document.uri} alt={document.name} style={imgStyle} />
        </Box>
      )}
      <Box display={'flex'} flexGrow={1}>
        <Stack>
          {docType === 'pdf' && (
            <Button
              variant={'outlined'}
              onClick={(e) => {
                e.stopPropagation();
                setOpenPdfViewer(true);
                dispatch(drawerChildIsOpenAction(true));
              }}
              startIcon={<FindInPageIcon />}
            >
              View PDF
            </Button>
          )}
          {!document.uri ||
            !docType ||
            (!imageExtensionTypes.includes(docType) && docType !== 'pdf' && (
              <Typography>No preview currently available.</Typography>
            ))}
          {editingMetadata?.createdByName && editingMetadata.dateCreated && (
            <Typography fontStyle={'italic'}>
              Added by {editingMetadata?.createdByName},{' '}
              {editingMetadata?.dateCreated ? tryFormatDate(editingMetadata.dateCreated) : ''}
            </Typography>
          )}
          {/*<form onSubmit={onSubmit}>*/}
          <TextField
            value={editingMetadata?.title ?? ''}
            label={'Title'}
            fullWidth
            onChange={(e) => {
              setDirty(true);
              setEditingMetadata({ ...editingMetadata, title: e.target.value });
            }}
          />
          <TextField
            value={editingMetadata?.description ?? ''}
            label={'Description'}
            fullWidth
            multiline
            rows={4}
            onChange={(e) => {
              setDirty(true);
              setEditingMetadata({ ...editingMetadata, description: e.target.value });
            }}
          />
          <Box display={'flex'} justifyContent={'right'}>
            <LoadingButton
              disabled={!dirty}
              loading={selectedDocument.submitting}
              variant={'outlined'}
              onClick={onSubmit}
            >
              Update
            </LoadingButton>
          </Box>
          {/*</form>*/}
        </Stack>
      </Box>
      {document.metadata?.documentType === SimpleDocumentType.Document && (
        <PdfViewer
          document={document}
          association={association}
          open={openPdfViewer}
          onClose={() => {
            setOpenPdfViewer(false);
            dispatch(drawerChildIsOpenAction(false));
          }}
        />
      )}
      {document.metadata?.documentType === SimpleDocumentType.Image && (
        <ImageViewer
          documents={[document]}
          open={openImageViewer}
          onClose={() => {
            setOpenImageViewer(false);
            dispatch(drawerChildIsOpenAction(false));
          }}
        />
      )}
    </Stack>
  );
};
