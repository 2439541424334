import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Assets } from './areas/assets/components/Assets';
import { Room } from './areas/room/components/Room';
import { NotFound } from './areas/notFound/NotFound';
import { PropertyTabs } from './areas/property/components/PropertyTabs';
import { BuildingTabs } from './areas/building/components/BuildingTabs';
import { UnitTabs } from './areas/unit/components/UnitTabs';
import { ApplicationWizard } from './areas/property/components/applications/applicationWizard/ApplicationWizard';
import { NotLoggedIn } from './areas/auth/NotLoggedIn';
import { useAuth0 } from '@auth0/auth0-react';
import { ApplicationDialog } from './areas/property/components/applications/applicationDialogs/ApplicationDialog';
import { LeaseDialog } from './areas/_shared/leases/components/leaseDialogs/LeaseDialog';
import { TaskDialog } from './areas/_shared/tasks/components/taskDialogs/TaskDialog';
import { AuthorizedClaims } from './areas/auth/AuthorizedClaims';
import { Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { TenantPortal } from './areas/tenantPortal/components/TenantPortal';
import { FullScreenLoading } from './areas/global/loading/FullScreenLoading';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { AcceptInvitations } from './areas/global/components/invitations/AcceptInvitations';
import { PaymentCompleted } from './areas/tenantPortal/components/payments/PaymentCompleted';
import { Profile } from './areas/global/components/profile/Profile';
import { ExpenseDialog } from './areas/_shared/expenses/components/dialogs/ExpenseDialog';
import { AssetTab, InfoTab, LeasingTab, OperationsTab, OverviewTab } from './areas/_shared/tabs/TabTypeEnums';
import { DetailsDrawer } from './areas/_shared/details/components/drawer/DetailsDrawer';
import { ScheduledTaskDrawer } from './areas/_shared/tasks/components/scheduledTasks/ScheduledTaskDrawer';
import { SopTaskDrawer } from './areas/property/components/propertyTabs/propertyInfoTabs/sopTaskTemplates/components/SopTaskDrawer';
import { VendorDrawer } from './areas/property/components/propertyTabs/propertyInfoTabs/vendorManagement/VendorDrawer';
import { AccountStatementViewDialog } from './areas/_shared/statements/components/accountStatementDialog/AccountStatementViewDialog';
import { IncomeDialog } from './areas/_shared/statements/components/income/IncomeDialog';
import { UnitTemplateDrawer } from './areas/_shared/unitTemplates/components/UnitTemplateDrawer';
import { ListingsPage } from './areas/listings/components/webView/ListingsPage';
import { AvailableListing } from './areas/listings/components/webView/AvailableListing';
import { ListingDrawer } from './areas/listings/components/assetView/ListingDrawer';
import { ViewEmailDialog } from './areas/_shared/communications/components/emailDialogs/ViewEmailDialog';
import { EmailTemplateDrawer } from './areas/_shared/emailTemplates/components/EmailTemplateDrawer';

export type AppRouterProps = {
  isAuthTokenSet: boolean;
};

export const AppRouter: FC<AppRouterProps> = ({ isAuthTokenSet }) => {
  const { isAuthenticated, isLoading: auth0Loading } = useAuth0();
  const { loading: currentUserLoading, value: currentUser } = useUser((u) => u.currentUser);

  return (
    <Routes>
      {isAuthenticated && isAuthTokenSet && (
        <>
          <Route path={appRoutes.acceptInvite} element={<AcceptInvitations />} />
        </>
      )}
      {isAuthenticated && isAuthTokenSet && currentUser ? (
        <>
          <Route
            path={appRoutes.home}
            element={
              currentUser?.claims?.find((c) => c.type !== Claims.Tenant) ? (
                <Navigate to={staticRoutes.propertyRoot} />
              ) : (
                <Navigate to={appRoutes.tenantPortal} />
              )
            }
          />
          <Route
            path={staticRoutes.propertyRoot}
            element={
              <AuthorizedClaims
                allowedClaims={[
                  'BillingOwner',
                  'PropertyManager',
                  'Admin',
                  'OwnerInvestor',
                  'Employee',
                  'ExternalVendor',
                ]}
                disablePropertyContext
              >
                <Assets />
              </AuthorizedClaims>
            }
          />
          <Route
            path={appRoutes.assets}
            element={
              <AuthorizedClaims
                allowedClaims={[
                  'BillingOwner',
                  'PropertyManager',
                  'Admin',
                  'OwnerInvestor',
                  'Employee',
                  'ExternalVendor',
                ]}
                disablePropertyContext
              >
                <Assets />
              </AuthorizedClaims>
            }
          >
            {/*these are the routes nested inside assets*/}
            <Route path={'*'} />
            <Route path={appRoutes.assetRoutes.property} element={<PropertyTabs />}>
              <Route
                path={appRoutes.assetRoutes.propertyRoutes.propertyApplicationModal}
                element={<ApplicationDialog />}
              />
              <Route path={appRoutes.assetRoutes.propertyRoutes.propertyLeaseModal} element={<LeaseDialog />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.propertyTaskModal} element={<TaskDialog />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.propertyExpenseModal} element={<ExpenseDialog />} />
              <Route
                path={appRoutes.assetRoutes.propertyRoutes.propertyEmailThreadModal}
                element={<ViewEmailDialog />}
              />
              <Route
                path={appRoutes.assetRoutes.propertyRoutes.propertyExpenseModalWithSelectedChild}
                element={<ExpenseDialog />}
              />
              <Route path={appRoutes.assetRoutes.propertyRoutes.vendorDrawer} element={<VendorDrawer />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.detailsDrawer} element={<DetailsDrawer />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.unitTemplate} element={<UnitTemplateDrawer />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.emailTemplate} element={<EmailTemplateDrawer />} />
              <Route
                path={appRoutes.assetRoutes.propertyRoutes.scheduledTaskDrawer}
                element={<ScheduledTaskDrawer />}
              />
              <Route path={appRoutes.assetRoutes.propertyRoutes.sopTaskDrawer} element={<SopTaskDrawer />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.statement} element={<AccountStatementViewDialog />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.incomeDrawer} element={<IncomeDialog />} />
              <Route path={appRoutes.assetRoutes.propertyRoutes.listing} element={<ListingDrawer />} />
            </Route>
            <Route path={appRoutes.assetRoutes.building} element={<BuildingTabs />}>
              <Route path={appRoutes.assetRoutes.buildingRoutes.buildingLeaseModal} element={<LeaseDialog />} />
              <Route
                path={appRoutes.assetRoutes.buildingRoutes.buildingApplicationModal}
                element={<ApplicationDialog />}
              />
              <Route path={appRoutes.assetRoutes.buildingRoutes.buildingTaskModal} element={<TaskDialog />} />
              <Route path={appRoutes.assetRoutes.buildingRoutes.buildingExpenseModal} element={<ExpenseDialog />} />
              <Route
                path={appRoutes.assetRoutes.buildingRoutes.buildingExpenseModalWithSelectedChild}
                element={<ExpenseDialog />}
              />
              <Route path={appRoutes.assetRoutes.buildingRoutes.detailsDrawer} element={<DetailsDrawer />} />
              <Route path={appRoutes.assetRoutes.buildingRoutes.unitTemplate} element={<UnitTemplateDrawer />} />
              <Route
                path={appRoutes.assetRoutes.buildingRoutes.scheduledTaskDrawer}
                element={<ScheduledTaskDrawer />}
              />
              <Route path={appRoutes.assetRoutes.buildingRoutes.sopTaskDrawer} element={<SopTaskDrawer />} />
              <Route path={appRoutes.assetRoutes.buildingRoutes.incomeDrawer} element={<IncomeDialog />} />
              <Route path={appRoutes.assetRoutes.buildingRoutes.listing} element={<ListingDrawer />} />
            </Route>
            <Route path={appRoutes.assetRoutes.unit} element={<UnitTabs />}>
              <Route path={appRoutes.assetRoutes.unitRoutes.unitLeaseModal} element={<LeaseDialog />} />
              <Route path={appRoutes.assetRoutes.unitRoutes.unitApplicationModal} element={<ApplicationDialog />} />
              <Route path={appRoutes.assetRoutes.unitRoutes.unitTaskModal} element={<TaskDialog />} />
              <Route path={appRoutes.assetRoutes.unitRoutes.unitExpenseModal} element={<ExpenseDialog />} />
              <Route path={appRoutes.assetRoutes.unitRoutes.detailsDrawer} element={<DetailsDrawer />} />
              <Route
                path={appRoutes.assetRoutes.unitRoutes.unitExpenseModalWithSelectedChild}
                element={<ExpenseDialog />}
              />
              <Route path={appRoutes.assetRoutes.unitRoutes.scheduledTaskDrawer} element={<ScheduledTaskDrawer />} />
              <Route path={appRoutes.assetRoutes.unitRoutes.sopTaskDrawer} element={<SopTaskDrawer />} />
              <Route path={appRoutes.assetRoutes.unitRoutes.incomeDrawer} element={<IncomeDialog />} />
            </Route>
            <Route path={appRoutes.assetRoutes.room} element={<Room />} />
          </Route>
          <Route
            path={appRoutes.tenantPortal}
            element={
              <AuthorizedClaims allowedClaims={['Tenant']} disablePropertyContext>
                <TenantPortal />
              </AuthorizedClaims>
            }
          >
            <Route path={appRoutes.tenantPortalRoutes.paymentCompleted} element={<PaymentCompleted />} />
          </Route>
          <Route
            path={appRoutes.tenantPortalRoutes.selectedLeaseId}
            element={
              <AuthorizedClaims allowedClaims={['Tenant']} disablePropertyContext>
                <TenantPortal />
              </AuthorizedClaims>
            }
          />
          <Route path={appRoutes.userRoutes.profile} element={<Profile />} />
        </>
      ) : (
        <>
          {auth0Loading || currentUserLoading ? (
            <Route path="*" element={<FullScreenLoading />} />
          ) : (
            <Route path="*" element={<NotLoggedIn />} />
          )}
        </>
      )}
      <Route path={appRoutes.application} element={<ApplicationWizard isAuthTokenSet={isAuthTokenSet} />} />
      <Route path={appRoutes.logout} element={<Navigate to={appRoutes.home} />} />
      <Route path={appRoutes.notLoggedIn} element={<NotLoggedIn />} />
      <Route path="*" element={<NotFound />} />
      <Route path={appRoutes.listingsWebView} element={<ListingsPage />} />
      <Route path={appRoutes.listingsRoutes.availableUnit} element={<AvailableListing />} />
    </Routes>
  );
};

export type AssetParams = {
  id: string;
  outerTab: AssetTab;
  innerTab: LeasingTab | OverviewTab | InfoTab | OperationsTab;
};

export const staticRoutes = {
  propertyRoot: 'assets/property',
};
export const appRoutes = {
  home: '/',
  assets: 'assets',
  userRoutes: {
    profile: 'profile',
  },
  assetRoutes: {
    property: 'property/:id/:outerTab/:innerTab',
    propertyRoutes: {
      propertyApplicationModal: 'application/:applicantId/:tab',
      propertyLeaseModal: 'lease/:leaseId/:tab',
      propertyTaskModal: 'task/:taskId/:tab',
      propertyExpenseModal: 'expense/:expenseId/:tab',
      propertyEmailThreadModal: 'emailThread/:emailThreadId',
      propertyExpenseModalWithSelectedChild: 'expense/:expenseId/:tab/:itemNumber',
      vendorDrawer: 'vendor/:vendorId/:tab',
      detailsDrawer: 'detail/:detailId/:tab',
      scheduledTaskDrawer: 'scheduledTask/:scheduledTaskId/:tab',
      sopTaskDrawer: 'sopTask/:sopTaskId/:tab',
      statement: 'statement/:accountId',
      incomeDrawer: 'selectedIncome/:incomeId',
      unitTemplate: 'unitTemplate/:templateId/:tab',
      listing: 'listing/:listingId/:tab',
      emailTemplate: 'emailTemplate/:emailTemplateId/:tab',
    },
    building: 'building/:id/:outerTab/:innerTab',
    buildingRoutes: {
      buildingApplicationModal: 'application/:applicantId/:tab',
      buildingLeaseModal: 'lease/:leaseId/:tab',
      buildingTaskModal: 'task/:taskId/:tab',
      buildingExpenseModal: 'expense/:expenseId/:tab',
      buildingExpenseModalWithSelectedChild: 'expense/:expenseId/:tab/:itemNumber',
      detailsDrawer: 'detail/:detailId/:tab',
      scheduledTaskDrawer: 'scheduledTask/:scheduledTaskId/:tab',
      sopTaskDrawer: 'sopTask/:sopTaskId/:tab',
      incomeDrawer: 'selectedIncome/:incomeId',
      unitTemplate: 'unitTemplate/:templateId/:tab',
      listing: 'listing/:listingId/:tab',
    },
    unit: 'unit/:id/:outerTab/:innerTab',
    unitRoutes: {
      unitApplicationModal: 'application/:applicantId/:tab',
      unitLeaseModal: 'lease/:leaseId/:tab',
      unitTaskModal: 'task/:taskId/:tab',
      unitExpenseModal: 'expense/:expenseId/:tab',
      unitExpenseModalWithSelectedChild: 'expense/:expenseId/:tab/:itemNumber',
      detailsDrawer: 'detail/:detailId/:tab',
      scheduledTaskDrawer: 'scheduledTask/:scheduledTaskId/:tab',
      sopTaskDrawer: 'sopTask/:sopTaskId/:tab',
      incomeDrawer: 'selectedIncome/:incomeId',
    },
    room: 'room/:id',
  },
  application: 'apply/:id',
  tenantPortal: 'tenantPortal',
  tenantPortalRoutes: {
    payment: 'payment',
    paymentCompleted: '/tenantPortal/paymentCompleted',
    home: '/tenantPortal',
    selectedLeaseId: '/tenantPortal/:leaseId',
  },
  listingsWebView: 'listings/:propertyId',
  listingsRoutes: {
    availableUnit: 'listings/:propertyId/listing/:listingId',
  },
  acceptInvite: 'acceptInvite',
  logout: '/logout',
  notLoggedIn: '/not-logged-in',
};
