import { all, put, takeLatest } from 'redux-saga/effects';
import {
  acceptDisbursementAction,
  acceptLeaseDepositAction,
  acceptLeasePaymentAction,
  applyLeaseChargeAction,
  applyLeaseCreditAction,
  disburseDepositAction,
  leaseTransactionFailAction,
  leaseTransactionSuccessAction,
  rectifyDepositAction,
  returnLeaseFundsAction,
} from './leaseTransactionSlice';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AcceptDisbursementHandlerRequest,
  AcceptPaymentHandlerRequest,
  ApplyChargeHandlerRequest,
  ApplyCreditHandlerRequest,
  DepositAcceptedHandlerRequest,
  DisburseDepositHandlerRequest,
  IAcceptDisbursementHandlerRequest,
  IAcceptPaymentHandlerRequest,
  IApplyChargeHandlerRequest,
  IApplyCreditHandlerRequest,
  IDepositAcceptedHandlerRequest,
  IDisburseDepositHandlerRequest,
  IReturnFundsHandlerRequest,
  ReconcileDepositHandlerRequest,
  ReturnFundsHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { LeaseDispatchType } from '@monkeyjump-labs/cam-fe-shared/dist/types/leaseTypes';
import { refreshLeaseTables } from './leasesSagas';
import { getAmountOwedToTenantAction, getDraftLeasesForPropertyIdAction } from './leasesSlice';

function* acceptDeposit(
  action: PayloadAction<{
    leaseId: string;
    propertyId: string;
    body: IDepositAcceptedHandlerRequest;
  }>,
) {
  try {
    const request = DepositAcceptedHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().lease_DepositAccepted, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Deposit accepted', severity: 'success' }));
    yield put(getDraftLeasesForPropertyIdAction({ propertyId: action.payload.propertyId }));
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem accepting deposit' }));
    yield put(leaseTransactionFailAction());
  }
}

function* applyCharge(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: IApplyChargeHandlerRequest;
  }>,
) {
  try {
    const request = ApplyChargeHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().lease_ApplyCharge, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Charge applied', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem applying charge' }));
    yield put(leaseTransactionFailAction());
  }
}

function* applyCredit(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: IApplyCreditHandlerRequest;
  }>,
) {
  try {
    const request = ApplyCreditHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().lease_ApplyCredit, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Credit applied', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem applying credit' }));
    yield put(leaseTransactionFailAction());
  }
}

function* acceptPayment(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: IAcceptPaymentHandlerRequest;
  }>,
) {
  try {
    const request = AcceptPaymentHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().payments_AcceptPayment, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Payment accepted', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem accepting payment' }));
    yield put(leaseTransactionFailAction());
  }
}

function* returnFunds(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: IReturnFundsHandlerRequest;
  }>,
) {
  try {
    const request = ReturnFundsHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().payments_ReturnFunds, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Funds returned', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
    yield put(getAmountOwedToTenantAction(action.payload.leaseId));
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem returning funds' }));
    yield put(leaseTransactionFailAction());
  }
}

function* acceptDisbursement(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: IAcceptDisbursementHandlerRequest;
  }>,
) {
  try {
    const request = AcceptDisbursementHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().payments_AcceptDisbursement, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Disbursement Accepted', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem accepting disbursement' }));
    yield put(leaseTransactionFailAction());
  }
}

function* disburseDeposit(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
    body: IDisburseDepositHandlerRequest;
  }>,
) {
  try {
    const request = DisburseDepositHandlerRequest.fromJS(action.payload.body);
    yield apiCall(ApiClientSingleton.getInstance().payments_DisburseDeposit, action.payload.leaseId, request);
    yield put(showToastMessageAction({ message: 'Deposit Disbursed', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem disbursing deposit' }));
    yield put(leaseTransactionFailAction());
  }
}

export function* rectifyDeposit(
  action: PayloadAction<{
    leaseId: string;
    assetId: string;
    leaseType: LeaseDispatchType;
  }>,
) {
  try {
    yield apiCall(
      ApiClientSingleton.getInstance().lease_RectifyDeposit,
      action.payload.leaseId,
      new ReconcileDepositHandlerRequest(),
    );
    yield put(showToastMessageAction({ message: 'Deposit reconciled successfully!', severity: 'success' }));
    yield refreshLeaseTables(action.payload.assetId, action.payload.leaseType);
    yield put(leaseTransactionSuccessAction());
  } catch (error) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem rectifying deposit' }));
    yield put(leaseTransactionFailAction());
  }
}

export function* leaseTransactionSaga() {
  yield all([
    takeLatest(acceptLeaseDepositAction.type, acceptDeposit),
    takeLatest(applyLeaseCreditAction.type, applyCredit),
    takeLatest(acceptLeasePaymentAction.type, acceptPayment),
    takeLatest(rectifyDepositAction.type, rectifyDeposit),
    takeLatest(returnLeaseFundsAction.type, returnFunds),
    takeLatest(acceptDisbursementAction.type, acceptDisbursement),
    takeLatest(disburseDepositAction.type, disburseDeposit),
    takeLatest(applyLeaseChargeAction.type, applyCharge),
  ]);
}
