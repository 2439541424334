import React, { FC } from 'react';
import { StyledInfoBox } from '../../../styledComponents/StyledInfoBox';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {
  AssociationType,
  IBaseTask,
  ITaskCategory,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { ScheduleTimeConfiguration } from './ScheduleTimeConfiguration';
import Grid from '@mui/material/Grid';
import { TaskCategoryAutocomplete } from '../TaskCategoryAutocomplete';
import { AssigneeAutocomplete, UserOption } from '../AssigneeAutocomplete';
import { Checklist } from '../../../checklists/components/Checklist';
import {
  addChecklistItemToScheduledTaskAction,
  addCommentToScheduledTaskChecklistItemAction,
  addDocumentToScheduledTaskChecklistItemAction,
  removeChecklistItemFromScheduledTaskAction,
  removeDocumentFromScheduledTaskAction,
  removeDocumentFromScheduledTaskChecklistItemAction,
  reorderChecklistItemsForScheduledTaskAction,
  updateScheduledTaskChecklistItemAction,
  uploadDocumentToScheduledTaskAction,
  useScheduledTasks,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { useDispatch } from 'react-redux';
import { DocumentList } from '../../../documents/components/DocumentList';
import { ReduxScheduledTask, ReduxTimeConfiguration } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { AssociateWithCurrentLeaseToggle } from '../AssociateWithCurrentLeaseToggle';
import Box from '@mui/material/Box';
import { TaskAssociationList } from '../taskDialogs/TaskAssociationList';

type EditScheduledTaskTabsProps = {
  scheduledTask: ReduxScheduledTask;
  onChangeTimeConfiguration: (config: ReduxTimeConfiguration) => void;
  onChangeTaskDetails: (field: keyof IBaseTask, value: string) => void;
  onCategorySelection: (value: ITaskCategory | null) => void;
  onAssigneeSelection: (value: UserOption | null | undefined) => void;
  onChangeScheduledDays: (days: number) => void;
  onChangeLeaseAssociation: () => void;
  currentTab: string;
};

export const EditScheduledTaskTabs: FC<EditScheduledTaskTabsProps> = ({
  scheduledTask,
  onChangeTaskDetails,
  onChangeTimeConfiguration,
  onCategorySelection,
  onAssigneeSelection,
  onChangeScheduledDays,
  onChangeLeaseAssociation,
  currentTab,
}) => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { selectedScheduledTask } = useScheduledTasks();

  const handleDocumentUpload = (file: File) => {
    if (selectedScheduledTask.value?.id) {
      dispatch(
        uploadDocumentToScheduledTaskAction({
          scheduledTaskId: selectedScheduledTask.value.id,
          file,
        }),
      );
    }
  };

  const handleRemoveDocument = (docId: string) => {
    if (selectedScheduledTask.value?.id) {
      dispatch(
        removeDocumentFromScheduledTaskAction({
          scheduledTaskId: selectedScheduledTask.value.id,
          docId,
        }),
      );
    }
  };

  const handleDeleteItemFromChecklist = (id: string) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        removeChecklistItemFromScheduledTaskAction({
          scheduledTaskId: selectedScheduledTask.value?.id,
          itemId: id,
        }),
      );
  };

  const handleAddItemToChecklist = (value: string) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        addChecklistItemToScheduledTaskAction({
          scheduledTaskId: selectedScheduledTask.value.id,
          item: value,
        }),
      );
  };

  const handleReorderChecklist = (ids: string[]) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        reorderChecklistItemsForScheduledTaskAction({
          scheduledTaskId: selectedScheduledTask.value.id,
          items: ids,
        }),
      );
  };

  const handleAddComment = (comment: string, itemId: string) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        addCommentToScheduledTaskChecklistItemAction({
          scheduledTaskId: selectedScheduledTask.value?.id,
          itemId: itemId,
          comment: comment,
        }),
      );
  };

  const handleUploadDocument = (file: File, itemId: string) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        addDocumentToScheduledTaskChecklistItemAction({
          itemId: itemId,
          scheduledTaskId: selectedScheduledTask.value?.id,
          file: file,
        }),
      );
  };

  const handleDeleteDocument = (docId: string, itemId: string) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        removeDocumentFromScheduledTaskChecklistItemAction({
          itemId: itemId,
          docId: docId,
          scheduledTaskId: selectedScheduledTask.value?.id,
        }),
      );
  };

  const handleUpdateChecklistItem = (itemId: string, newName?: string, newDescription?: string) => {
    selectedScheduledTask.value?.id &&
      dispatch(
        updateScheduledTaskChecklistItemAction({
          scheduledTaskId: selectedScheduledTask.value?.id,
          itemId: itemId,
          body: { name: newName, description: newDescription },
        }),
      );
  };

  return (
    <Box sx={{ p: '1rem', width: '100%' }}>
      {
        {
          info: (
            <StyledInfoBox label={'Info'}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant={'outlined'}
                    label={'Task Name'}
                    sx={{ margin: 0 }}
                    value={scheduledTask.task?.name ? scheduledTask.task.name : ''}
                    onChange={(e) => onChangeTaskDetails('name', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TaskCategoryAutocomplete onChange={onCategorySelection} value={scheduledTask.task?.category} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <AssigneeAutocomplete onValueChange={onAssigneeSelection} value={scheduledTask.task?.assignee} />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label={'Days Before Start Date to Schedule'}
                    required
                    type={'number'}
                    value={scheduledTask.daysPriorToStartDateToConvert}
                    onChange={(e) => onChangeScheduledDays(Number(e.target.value))}
                  />
                </Grid>
                <Grid item xs={6}>
                  {selectedContext.unitId && (
                    <AssociateWithCurrentLeaseToggle
                      value={scheduledTask.associateWithCurrentLease ?? false}
                      onChange={onChangeLeaseAssociation}
                      inScheduledTask
                    />
                  )}
                </Grid>
              </Grid>
              <ScheduleTimeConfiguration
                timeConfiguration={scheduledTask.timeConfiguration}
                onSetTimeConfiguration={onChangeTimeConfiguration}
                editingMode
              />
            </StyledInfoBox>
          ),
          description: (
            <>
              <StyledInfoBox label={'Description'}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    label={'Description'}
                    multiline
                    minRows={2}
                    variant={'outlined'}
                    value={scheduledTask.task?.description ? scheduledTask.task?.description : ''}
                    onChange={(e) => onChangeTaskDetails('description', e.target.value)}
                  />
                  <Checklist
                    checklist={scheduledTask.task?.checklist}
                    onAddItem={handleAddItemToChecklist}
                    onDeleteItem={handleDeleteItemFromChecklist}
                    submitting={selectedScheduledTask.submitting}
                    onReorderChecklist={handleReorderChecklist}
                    associationType={AssociationType.ScheduledTask}
                    associatedId={selectedScheduledTask.value?.id}
                    onAddComment={handleAddComment}
                    onUploadDocument={handleUploadDocument}
                    onDeleteDocument={handleDeleteDocument}
                    onUpdateChecklistItem={handleUpdateChecklistItem}
                    propertyId={selectedScheduledTask.value?.propertyId}
                  />
                </Stack>
              </StyledInfoBox>
              <TaskAssociationList
                associationType={AssociationType.ScheduledTask}
                id={selectedScheduledTask.value?.id}
              />
            </>
          ),
          documents: (
            <StyledInfoBox label={'Documents'}>
              <DocumentList
                propertyId={selectedContext.propertyId}
                association={{
                  associatedId: selectedScheduledTask.value?.id,
                  associationType: AssociationType.ScheduledTask,
                }}
                value={selectedScheduledTask.value?.task?.documents ?? []}
                onUpload={handleDocumentUpload}
                uploading={selectedScheduledTask.submitting}
                onDeleteDocument={handleRemoveDocument}
              />
            </StyledInfoBox>
          ),
        }[currentTab || 'info']
      }
    </Box>
  );
};
