import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { CardMedia } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import { IPostalAddress } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { ListingDetails } from './details/ListingDetails';
import { useIcons } from '../../../_shared/icons/useIcons';
import { useDispatch } from 'react-redux';
import { deleteListingAction, useListings } from '../../redux/listingSlice';
import { LoadingIconButton } from '../../../_shared/buttons/LoadingIconButton';
import Tooltip from '@mui/material/Tooltip';
import { ListingStatusBar } from './ListingStatusBar';
import { ReduxListing } from '../../redux/listingData';

type ListingCardProps = {
  listing: ReduxListing;
  isPropertyView?: boolean;
  onOpenApply?: () => void;
  isPropertyPreview?: boolean;
};

export const handleFormatAddress = (address: IPostalAddress | undefined): string => {
  if (!address) return '';
  if (address.streetAddress2)
    return `${address.streetAddress1}, ${address.streetAddress2}, ${address.city}, ${address.state}, ${address.zip}`;
  return `${address.streetAddress1}, ${address.city}, ${address.state}, ${address.zip}`;
};

export const ListingCard: FC<ListingCardProps> = ({ listing, isPropertyView, isPropertyPreview, onOpenApply }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listings } = useListings();
  const { ActionType, getActionIcon } = useIcons();
  const [hover, setHover] = React.useState(false);

  const handleOpenApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onOpenApply && onOpenApply();
  };

  const handleDeleteListing = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: string) => {
    e.stopPropagation();
    id && dispatch(deleteListingAction(id));
  };

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <Card
      sx={styles.card}
      onClick={() => navigate(isPropertyView ? `listing/${listing.id}/info` : `listing/${listing.id}`)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isPropertyView && !isPropertyPreview && <ListingStatusBar listing={listing} />}
      <CardHeader
        title={handleFormatAddress(listing.address)}
        titleTypographyProps={{
          color: 'primary',
          fontSize: 25,
          fontWeight: 500,
          style: { textDecoration: hover ? 'underline' : 'none' },
        }}
      ></CardHeader>
      <CardMedia
        alt={listing.photos && listing.photos.length > 0 ? `Listing ${listing.id}` : 'No Image Available'}
        component="img"
        height="200"
        image={listing.photos && listing.photos.length > 0 ? listing.photos[0].uri : ''}
      />
      <CardContent sx={styles.content}>
        <ListingDetails listing={listing} />
      </CardContent>
      <CardActions sx={styles.actions}>
        {!isPropertyView && (
          <Button
            variant={'outlined'}
            onClick={() => navigate(isPropertyView ? `listing/${listing.id}/info` : `listing/${listing.id}`)}
            disabled={isPropertyPreview}
          >
            View Details
          </Button>
        )}
        {isPropertyView ? (
          <Tooltip title={'Delete'}>
            <LoadingIconButton
              onClick={(e) => handleDeleteListing(e, listing.id)}
              icon={getActionIcon(ActionType.Delete)}
              loading={listings.submitting}
            />
          </Tooltip>
        ) : (
          <Button variant={'contained'} onClick={(e) => handleOpenApply(e)} disabled={isPropertyPreview}>
            Apply Now
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'column',
    py: 1,
    height: '100%',
    '&:hover': {
      boxShadow: 6,
      cursor: 'pointer',
    },
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'right',
  },
};
