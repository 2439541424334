import React, { FC, useEffect, useState } from 'react';
import { DrawerLayout } from '../../../../../global/layout/DrawerLayout';
import {
  AssociationType,
  IAddVendorHandlerRequest,
  IContactPerson,
  IUpdateVendorHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import PersonIcon from '@mui/icons-material/Person';
import { VendorInfoForm } from './VendorInfoForm';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addDocumentToVendorAction,
  listVendorsForPropertyAction,
  removeDocumentFromVendorAction,
  updateVendorForPropertyAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import { StyledInfoBox } from '../../../../../_shared/styledComponents/StyledInfoBox';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { ContactsTable } from './ContactsTable';
import Stack from '@mui/material/Stack';
import { DocumentList } from '../../../../../_shared/documents/components/DocumentList';
import { ReduxVendor } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import { AssetParams } from '../../../../../../AppRouter';
import { useIcons } from '../../../../../_shared/icons/useIcons';

type AddOrUpdateVendor = IAddVendorHandlerRequest | IUpdateVendorHandlerRequest;

enum VendorDrawerTabs {
  Info = 'info',
  Contacts = 'contacts',
  Documents = 'documents',
}

type VendorDrawerParams = {
  vendorId: string;
  tab: string;
} & AssetParams;

export const VendorDrawer: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getTabIcon } = useIcons();
  const { id, outerTab, innerTab, vendorId, tab } = useParams<VendorDrawerParams>();
  const { vendors } = useProperty();
  const [vendor, setVendor] = useState<ReduxVendor | undefined>(vendors.value?.find((v) => v.id === vendorId));
  useEffect(() => {
    vendorId && setVendor(vendors.value?.find((v) => v.id === vendorId));
  }, [vendorId, vendors]);

  useEffect(() => {
    if (id) dispatch(listVendorsForPropertyAction({ propertyId: id }));
  }, [id]);

  const tabs = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: VendorDrawerTabs.Info,
    },
    {
      icon: <PersonIcon />,
      label: 'Contacts',
      value: VendorDrawerTabs.Contacts,
    },
    {
      icon: getTabIcon('document'),
      label: 'Documents',
      value: VendorDrawerTabs.Documents,
    },
  ];

  const handleTabChange = (newValue: string) => {
    navigate(`/assets/property/${id}/${outerTab}/${innerTab}/vendor/${vendorId}/${newValue}`);
  };

  const handleClose = () => {
    navigate(`/assets/property/${id}/${outerTab}/${innerTab}`);
  };

  const handleAddDocument = (file: File) => {
    if (id && vendorId) {
      dispatch(addDocumentToVendorAction({ propertyId: id, vendorId: vendorId, file: file }));
    }
  };

  const handleRemoveDocument = (docId: string) => {
    if (docId && id && vendorId) {
      dispatch(removeDocumentFromVendorAction({ propertyId: id, vendorId: vendorId, docId: docId }));
    }
  };

  const handleSave = (value: AddOrUpdateVendor) => {
    if (id && vendorId) {
      dispatch(
        updateVendorForPropertyAction({
          propertyId: id,
          id: vendorId,
          body: value,
        }),
      );
    }
  };

  const handleSaveContacts = (contacts: IContactPerson[]) => {
    handleSave({
      ...vendor,
      contacts: contacts,
    });
  };

  return (
    <>
      {vendor && (
        <DrawerLayout
          title={vendor.name ?? 'Unnamed Vendor'}
          tabValue={tab}
          open
          onCloseDrawer={handleClose}
          tabs={tabs}
          onTabChange={handleTabChange}
        >
          {{
            info: (
              <Box>
                <StyledInfoBox label={'Info'}>
                  <VendorInfoForm
                    value={vendor}
                    onSubmit={handleSave}
                    submitting={vendors.submitting}
                    newOrEdit={'edit'}
                  />
                </StyledInfoBox>
              </Box>
            ),
            contacts: (
              <Stack margin={2} sx={{ width: '100%' }}>
                <StyledInfoBox label={'Contacts'}>
                  <ContactsTable
                    loading={vendors.loading || vendors.submitting}
                    value={vendor.contacts ?? []}
                    onSave={handleSaveContacts}
                  />
                </StyledInfoBox>
              </Stack>
            ),
            documents: (
              <Stack margin={2} sx={{ width: '100%' }}>
                <StyledInfoBox label={'Documents'}>
                  <DocumentList
                    propertyId={vendor.propertyId}
                    association={{ associatedId: vendorId, associationType: AssociationType.Vendor }}
                    value={vendor.documents}
                    uploading={vendors.submitting}
                    onUpload={handleAddDocument}
                    onDeleteDocument={handleRemoveDocument}
                  />
                </StyledInfoBox>
              </Stack>
            ),
          }[tab ?? 'info'] || <>Error</>}
        </DrawerLayout>
      )}
    </>
  );
};
