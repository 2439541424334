import createSagaMiddleware from 'redux-saga';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';
import { rootSaga } from './rootSaga';
import { roomSlice } from '../areas/room/redux/roomSlice';
import { notificationsSlice } from '@monkeyjump-labs/cam-fe-shared/dist/redux/notifications/notificationSlice';
import {
  leasesSlice,
  uploadDocumentAction as uploadLeaseDocumentAction,
} from '../areas/_shared/leases/redux/leasesSlice';
import {
  applicationSlice,
  uploadDocumentAction as uploadApplicationDocumentAction,
} from '../areas/property/redux/applicationSlice';
import { searchSlice } from '../areas/_shared/search/redux/searchSlice';
import { statementSlice } from '../areas/_shared/statements/redux/statementSlice';
import { uploadDocumentToTaskAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { leaseTransactionSlice } from '../areas/_shared/leases/redux/leaseTransactionSlice';
import { addDocumentToTaskAutomationAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import LogRocket from 'logrocket';
import { tenantSlice, uploadTenantDocToTaskAction } from '../areas/tenantPortal/redux/tenantSlice';
import { uploadDocumentToScheduledTaskAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { expenseSlice } from '../areas/_shared/expenses/redux/expenseSlice';
import {
  addDocumentToDetailAction,
  addDocumentToDetailObservationAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { documentSlice } from '../areas/_shared/documents/redux/documentSlice';
import { reportSlice } from '../areas/property/redux/reportSlice';
import { bankAccountsSlice } from '../areas/_shared/bankAccounts/redux/bankAccountsSlice';
import { unitTemplateSlice } from '../areas/_shared/unitTemplates/redux/unitTemplateSlice';
import { addPhotoAction, listingSlice } from '../areas/listings/redux/listingSlice';
import { configureCamStore } from '@monkeyjump-labs/cam-fe-shared/dist/redux/store';
import { configureCamSagas } from '@monkeyjump-labs/cam-fe-shared/dist/redux/rootSagas';
import { fileUploadingAction, showErrorAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { emailTemplateSlice } from '../areas/_shared/emailTemplates/redux/emailTemplateSlice';
import { calendarSlice } from '../areas/_shared/calendar/redux/calendarSlice';

const sagaMiddleware = createSagaMiddleware();

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export const store = configureCamStore({
  reducer: {
    room: roomSlice.reducer,
    notifications: notificationsSlice.reducer,
    leases: leasesSlice.reducer,
    leaseTransactions: leaseTransactionSlice.reducer,
    statements: statementSlice.reducer,
    applications: applicationSlice.reducer,
    expenses: expenseSlice.reducer,
    router: routerReducer,
    search: searchSlice.reducer,
    tenant: tenantSlice.reducer,
    bankAccounts: bankAccountsSlice.reducer,
    documents: documentSlice.reducer,
    reports: reportSlice.reducer,
    unitTemplates: unitTemplateSlice.reducer,
    listings: listingSlice.reducer,
    emailTemplates: emailTemplateSlice.reducer,
    calendar: calendarSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          // Ignore file upload action, as we aren't actually persisting it to redux
          uploadApplicationDocumentAction.type,
          uploadLeaseDocumentAction.type,
          fileUploadingAction.type,
          uploadDocumentToTaskAction.type,
          uploadTenantDocToTaskAction.type,
          uploadDocumentToScheduledTaskAction.type,
          addDocumentToTaskAutomationAction.type,
          addDocumentToDetailAction.type,
          addDocumentToDetailObservationAction.type,
          addPhotoAction.type,
          //ignore error messages
          showErrorAction.type,
        ],
      },
    }).concat(sagaMiddleware, routerMiddleware, LogRocket.reduxMiddleware()),
});

sagaMiddleware.run(configureCamSagas(rootSaga));

export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
