import { all, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  resetAssociationSubmissionAction,
  setAssociationSubmittingSuccessAction,
} from '../../areas/_shared/search/redux/searchSlice';
import {
  addAssociationToEmailAction,
  addAssociationToEmailFailAction,
  addAssociationToEmailSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/communications/communicationSlice';
import {
  addAttachmentToSelectedExpenseAction,
  addAttachmentToSelectedExpenseFailAction,
  addAttachmentToSelectedExpenseSuccessAction,
} from '../../areas/_shared/expenses/redux/expenseSlice';
import {
  addAssociationToDetailFailAction,
  addAssociationToDetailSuccessAction,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';

function* updateSelectedExpenseWhenAddedToEmail(action: PayloadAction<AssociationType>) {
  if (action.payload === AssociationType.Expense) {
    yield put(addAttachmentToSelectedExpenseAction());
  }
}

function* updateSelectedExpenseWhenAddedToEmailSuccess(action: PayloadAction<AssociationType>) {
  yield put(setAssociationSubmittingSuccessAction());
  if (action.payload === AssociationType.Expense) {
    yield put(addAttachmentToSelectedExpenseSuccessAction());
  }
}

function* updateSelectedExpenseWhenAddedToEmailFail(action: PayloadAction<AssociationType>) {
  yield put(resetAssociationSubmissionAction());
  if (action.payload === AssociationType.Expense) {
    yield put(addAttachmentToSelectedExpenseFailAction());
  }
}

function* updateAssociationSubmissionStateSuccess() {
  yield put(setAssociationSubmittingSuccessAction());
}

function* updateAssociationSubmissionStateFail() {
  yield put(resetAssociationSubmissionAction());
}

export function* stateSagas() {
  yield all([
    takeEvery(addAssociationToEmailAction.type, updateSelectedExpenseWhenAddedToEmail),
    takeEvery(addAssociationToEmailSuccessAction.type, updateSelectedExpenseWhenAddedToEmailSuccess),
    takeEvery(addAssociationToEmailFailAction.type, updateSelectedExpenseWhenAddedToEmailFail),
    takeEvery(addAssociationToDetailSuccessAction.type, updateAssociationSubmissionStateSuccess),
    takeEvery(addAssociationToDetailFailAction.type, updateAssociationSubmissionStateFail),
  ]);
}
