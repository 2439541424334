import React, { FC, useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import TextField from '@mui/material/TextField';
import { ChecklistItem } from './ChecklistItem';
import CircularProgress from '@mui/material/CircularProgress';
import { ReduxChecklistItem } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Tooltip from '@mui/material/Tooltip';
import { DroppableComponent } from '../../dragAndDrop/DroppableComponent';

type ChecklistProps = {
  propertyId?: string;
  checklist: ReduxChecklistItem[] | undefined;
  onAddItem: (value: string) => void;
  onChecked?: (id: string) => void;
  onDeleteItem: (id: string) => void;
  submitting: boolean;
  onReorderChecklist: (checklistIds: string[]) => void;
  associatedId?: string;
  associationType: AssociationType;
  onAddComment: (comment: string, itemId: string) => void;
  onUploadDocument: (file: File, itemId: string) => any;
  onDeleteDocument: (docId: string, itemId: string) => void;
  onUpdateChecklistItem: (itemId: string, newName?: string, newDescription?: string) => void;
};

export const Checklist: FC<ChecklistProps> = ({
  propertyId,
  checklist,
  onAddItem,
  onDeleteItem,
  onChecked,
  submitting,
  onReorderChecklist,
  onDeleteDocument,
  onUploadDocument,
  associationType,
  associatedId,
  onAddComment,
  onUpdateChecklistItem,
}) => {
  const [newItem, setNewItem] = useState<string>('');
  const [localChecklist, setLocalChecklist] = useState(checklist);

  useEffect(() => {
    setLocalChecklist(checklist);
  }, [checklist]);

  const handleAddItem = () => {
    onAddItem(newItem);
    setNewItem('');
  };

  const handleDragEnd = (sourceIndex: number, destinationIndex: number, id: string) => {
    if (!checklist) return;
    const newTaskIds: string[] = checklist?.map((c) => c.id!);
    newTaskIds.splice(sourceIndex, 1);
    newTaskIds.splice(destinationIndex, 0, id);
    onReorderChecklist(newTaskIds);
  };

  const handleDragUpdate = (destinationIndex: number, id: string) => {
    if (!localChecklist) return;
    const insertItem = [...localChecklist].find((item) => item.id === id);
    if (!insertItem) return;
    const filteredChecklist = localChecklist.filter((item) => item.id !== id);
    filteredChecklist.splice(destinationIndex, 0, insertItem);
    setLocalChecklist(filteredChecklist);
  };

  return (
    <>
      <DroppableComponent droppableId={'droppable-checklist'} onDragUpdate={handleDragUpdate} onDragEnd={handleDragEnd}>
        {localChecklist?.map((value, index) => {
          return (
            <ChecklistItem
              key={value.id}
              value={value}
              propertyId={propertyId}
              index={index}
              onDeleteItem={onDeleteItem}
              onChecked={onChecked}
              loading={submitting}
              associationType={associationType}
              associatedId={associatedId}
              onDeleteDocument={onDeleteDocument}
              onAddComment={onAddComment}
              onUploadDocument={onUploadDocument}
              onUpdateChecklistItem={onUpdateChecklistItem}
            />
          );
        })}
      </DroppableComponent>
      <ListItem
        secondaryAction={
          <IconButton edge="end" aria-label="edit" disabled={submitting} onClick={handleAddItem} type="button">
            {submitting ? (
              <CircularProgress size={24} />
            ) : (
              <Tooltip title={'Add Checklist Item'}>
                <AddCircleIcon />
              </Tooltip>
            )}
          </IconButton>
        }
      >
        <TextField
          value={newItem}
          multiline
          label={'Add Item to Checklist'}
          fullWidth
          onChange={(e) => setNewItem(e.target.value)}
        />
      </ListItem>
    </>
  );
};
