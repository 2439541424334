import { all, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  AssociationChildType,
  AssociationType,
  FileResponse,
  IUpdateDocumentMetadataHandlerRequest,
  UpdateDocumentMetadataHandlerRequest,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  downloadTemplateAction,
  downloadTemplateFailAction,
  downloadTemplateSuccessAction,
  exportGlEntriesAction,
  exportGlEntriesFailAction,
  exportGlEntriesSuccessAction,
  updateDocumentMetadataAction,
  updateDocumentMetadataFailAction,
  updateDocumentMetadataSuccessAction,
  uploadBulkAssetsAction,
  uploadBulkAssetsFailAction,
  uploadBulkAssetsSuccessAction,
} from './documentSlice';
import { apiCall, ApiClientSingleton } from '@monkeyjump-labs/cam-fe-shared/dist/services/buildApiClient';
import { showErrorAction, showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { getSingleLeaseAction } from '../../leases/redux/leasesSlice';
import { getSelectedTaskAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/taskSlice';
import { getScheduledTaskByIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/scheduledTaskSlice';
import { getDetailByIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/details/detailsSlice';
import { getSingleAutomationByPropertyIdAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/sopTaskSlice';
import { getSingleApplicationByIdAction } from '../../../property/redux/applicationSlice';
import { getSelectedExpenseByIdAction } from '../../expenses/redux/expenseSlice';
import { updateListingPhotoMetadataSuccessAction } from '../../../listings/redux/listingSlice';
import { downloadFileUtils } from '../../utils/DownloadFileUtils';
import { getAllAssetsAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import { saveAs } from 'file-saver';
import { ReduxDate } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';

function* updateDocumentMetadata(
  action: PayloadAction<{
    propertyId: string;
    associationType: AssociationType;
    associationId: string;
    childAssociationType?: AssociationChildType;
    childAssociationId?: string;
    documentId: string;
    body: IUpdateDocumentMetadataHandlerRequest;
  }>,
) {
  try {
    console.log(action.payload);
    yield apiCall(
      ApiClientSingleton.getInstance().documents_UpdateDocumentMetadata,
      action.payload.propertyId,
      action.payload.associationType,
      action.payload.associationId,
      action.payload.documentId,
      action.payload.childAssociationType as unknown as AssociationChildType,
      action.payload.childAssociationId as unknown as string,
      new UpdateDocumentMetadataHandlerRequest(action.payload.body),
    );
    yield put(updateDocumentMetadataSuccessAction());
    if (action.payload.associationId) {
      switch (action.payload.associationType) {
        case AssociationType.Lease:
          yield put(getSingleLeaseAction({ leaseId: action.payload.associationId, openLeaseModal: true }));
          break;
        case AssociationType.Task:
          yield put(getSelectedTaskAction({ id: action.payload.associationId, includeClosed: false }));
          break;
        case AssociationType.ScheduledTask:
          yield put(getScheduledTaskByIdAction(action.payload.associationId));
          break;
        case AssociationType.Detail:
        case AssociationType.DetailObservation:
          yield put(getDetailByIdAction({ detailId: action.payload.associationId }));
          break;
        case AssociationType.TaskConfiguration:
          yield;
          put(
            getSingleAutomationByPropertyIdAction({
              propertyId: action.payload.propertyId,
              configId: action.payload.associationId,
            }),
          );
          break;
        case AssociationType.Application:
          yield put(getSingleApplicationByIdAction(action.payload.associationId));
          break;
        case AssociationType.Expense:
          yield put(getSelectedExpenseByIdAction(action.payload.associationId));
          break;
        case AssociationType.Listing:
          yield put(
            updateListingPhotoMetadataSuccessAction({
              listingId: action.payload.associationId,
              documentId: action.payload.documentId,
              title: action.payload.body.title,
              description: action.payload.body.description,
            }),
          );
          break;
        default:
          break;
      }
    }
    yield put(showToastMessageAction({ message: 'Document updated successfully' }));
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'Problem updating document' }));
    yield put(updateDocumentMetadataFailAction());
  }
}

function* downloadTemplate(action: PayloadAction<number>) {
  try {
    const response: FileResponse = yield apiCall(ApiClientSingleton.getInstance().template, action.payload);
    if (response) {
      downloadFileUtils(response.data, response.fileName ?? 'AssetUploadTemplate.xlsx');
    } else {
      yield put(
        showToastMessageAction({
          message: 'something went wrong downloading import template',
          severity: 'error',
        }),
      );
    }
    yield put(showToastMessageAction({ message: 'Template downloaded successfully', severity: 'success' }));
    yield put(downloadTemplateSuccessAction());
  } catch (error: any) {
    yield put(showErrorAction({ error, fallbackMessage: 'problem downloading asset import template' }));
    yield put(downloadTemplateFailAction());
  }
}

function* uploadBulkAssets(action: PayloadAction<{ file: File; includeArchived: boolean }>) {
  try {
    yield apiCall(ApiClientSingleton.getInstance().import, {
      data: action.payload.file,
      fileName: action.payload.file.name,
    });
    yield put(showToastMessageAction({ message: 'Bulk assets uploaded successfully!' }));
    yield put(uploadBulkAssetsSuccessAction());
    yield put(getAllAssetsAction());
  } catch (error: any) {
    yield put(uploadBulkAssetsFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'Problem importing bulk assets' }));
  }
}

function* exportGlEntries(action: PayloadAction<{ propertyId: string; startDate: ReduxDate; endDate: ReduxDate }>) {
  try {
    const response: FileResponse = yield apiCall(
      ApiClientSingleton.getInstance().properties_GetGeneralLedgerExport,
      action.payload.propertyId,
      action.payload.startDate,
      action.payload.endDate,
    );
    saveAs(response.data, response.fileName);
    yield put(exportGlEntriesSuccessAction());
  } catch (error: any) {
    yield put(exportGlEntriesFailAction());
    yield put(showErrorAction({ error, fallbackMessage: 'problem exporting GL Entries' }));
  }
}

export function* documentSaga() {
  yield all([
    takeLatest(updateDocumentMetadataAction.type, updateDocumentMetadata),
    takeLatest(downloadTemplateAction.type, downloadTemplate),
    takeLatest(uploadBulkAssetsAction.type, uploadBulkAssets),
    takeLatest(exportGlEntriesAction.type, exportGlEntries),
  ]);
}
