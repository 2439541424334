import React, { FC, useEffect, useState } from 'react';
import { StyledInfoBox } from '../../../../_shared/styledComponents/StyledInfoBox';
import { useDispatch } from 'react-redux';
import { useAssets } from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/assetSlice';
import {
  getGlCodesAction,
  updateGlCodeEnabledAction,
  useProperty,
} from '@monkeyjump-labs/cam-fe-shared/dist/redux/assets/propertySlice';
import Stack from '@mui/material/Stack';
import { AccountType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import Typography from '@mui/material/Typography';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { AccountListItem } from './AccountListItem';
import { useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { Claim, Claims } from '@monkeyjump-labs/cam-fe-shared/dist/types/constants';
import { Roles } from '../../../../../app/constants';
import { AccountDialog } from '../../../../assets/components/addAssets/AccountDialog';
import { ExportGLEntriesDialog } from './ExportGLEntriesDialog';
import Add from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';

export const ChartOfAccounts: FC = () => {
  const dispatch = useDispatch();
  const { selectedContext } = useAssets();
  const { glAccounts } = useProperty();
  const userInfo = useUser((u) => u.currentUser.value);
  const allowedClaims: Claim[] = ['Admin', 'BillingOwner', 'PropertyManager'];
  const isAuthorized =
    userInfo?.roles?.some((r) => r === Roles.SuperAdmin) ||
    allowedClaims.some((a) =>
      userInfo?.claims?.some((c) => c.type === Claims[a] && c.value === selectedContext.propertyId),
    );
  const [DialogOpen, setDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [editId, setEditId] = useState<string | undefined>(undefined);
  const [activeAccountTypes, setActiveAccountTypes] = useState<{ [key: string]: string }>({});

  // a PascalCase to Sentence Case regex
  const pascalToSentence = (str: string) => str.replace(/([A-Z])/g, ' $1').trim();

  useEffect(() => {
    if (glAccounts.loaded) {
      const accountTypes: { [key: string]: string } = Object.fromEntries(
        Object.keys(AccountType)
          .map((key) => key)
          .filter((k) => glAccounts.value?.find((a) => a.accountType === k))
          .map((key) => [key, pascalToSentence(key)]),
      );
      setActiveAccountTypes(accountTypes);
    } else {
      setActiveAccountTypes({});
    }
  }, [glAccounts.loaded, glAccounts.value]);

  useEffect(() => {
    if (selectedContext.propertyId) {
      dispatch(
        getGlCodesAction({
          propertyId: selectedContext.propertyId,
          includeBalance: true,
          includeDisabled: true,
          includeUnassignable: true,
        }),
      );
    }
  }, []);

  const onCloseAddDialog = () => {
    setDialogOpen(false);
    setEditId(undefined);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const openExportDialog = () => {
    setExportDialogOpen(true);
  };

  const onCloseExportDialog = () => {
    setExportDialogOpen(false);
  };

  const handleEnabledChange = (accountId: string, checked: boolean) => {
    dispatch(
      updateGlCodeEnabledAction({
        accountId: accountId,
        isEnabled: checked,
        propertyId: selectedContext.propertyId || '',
      }),
    );
  };

  const handleEdit = (accountId: string) => {
    setEditId(accountId);
    openDialog();
  };

  return (
    <StyledInfoBox label={'Chart of Accounts'}>
      <Stack spacing={2}>
        <Stack direction={'row'} spacing={2}>
          <Button variant="outlined" startIcon={<Add />} onClick={openDialog}>
            Add Account
          </Button>
          <Button variant="outlined" onClick={openExportDialog}>
            Export GL Entries
          </Button>
        </Stack>
        {glAccounts.loading ? (
          <LinearProgress />
        ) : (
          Object.entries(activeAccountTypes).map(([accountTypeKey, accountTypeName]) => {
            return (
              <Accordion key={accountTypeKey}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={accountTypeKey + `-content`}
                  id={accountTypeKey + `-content`}
                >
                  <Typography variant={'h5'}>{accountTypeName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={1}>
                    {glAccounts.loading ? (
                      <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                        <CircularProgress />
                      </Box>
                    ) : glAccounts.value?.find((x) => x.accountType === accountTypeKey) ? (
                      glAccounts.value?.map((glAccount) => {
                        if (glAccount.accountType === accountTypeKey) {
                          return (
                            <div key={glAccount.id}>
                              <Divider />
                              <AccountListItem
                                account={glAccount}
                                onEnabledChange={handleEnabledChange}
                                onEdit={handleEdit}
                                isAuthorized={isAuthorized}
                              />
                            </div>
                          );
                        }
                      })
                    ) : (
                      <Typography fontStyle={'italic'}>No Accounts</Typography>
                    )}
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Stack>
      {selectedContext.propertyId && (
        <AccountDialog
          propertyId={selectedContext.propertyId}
          accounts={glAccounts.value!}
          open={DialogOpen}
          onClose={onCloseAddDialog}
          editId={editId}
        ></AccountDialog>
      )}
      {selectedContext.propertyId && (
        <ExportGLEntriesDialog
          propertyId={selectedContext.propertyId}
          open={exportDialogOpen}
          onClose={onCloseExportDialog}
        />
      )}
    </StyledInfoBox>
  );
};
