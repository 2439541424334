import React, { FC, useEffect, useState } from 'react';
import { ReduxDocument } from '@monkeyjump-labs/cam-fe-shared/dist/types/reduxTypes';
import Stack from '@mui/material/Stack';
import { FileUploadButton } from '../../buttons/FileUploadButton';
import Box from '@mui/material/Box';
import { ReorderableDocumentCard } from './ReorderableDocumentCard';
import { DroppableComponent } from '../../dragAndDrop/DroppableComponent';

type ReorderableDocumentListProps = {
  listingId?: string;
  documents: ReduxDocument[];
  onAddDocument: (file: File) => void;
  onRemoveDocument: (documentId: string) => void;
  onReorderDocuments: (documentIds: string[]) => void;
  loading?: boolean;
};

export const ReorderableDocumentList: FC<ReorderableDocumentListProps> = ({
  documents,
  onRemoveDocument,
  onAddDocument,
  onReorderDocuments,
  loading,
  listingId,
}) => {
  const [localPhotoList, setLocalPhotoList] = useState(documents);

  useEffect(() => {
    setLocalPhotoList(documents);
  }, [documents]);

  const handleDragEnd = (sourceIndex: number, destinationIndex: number, id: string) => {
    if (!documents) return;
    const newDocIds: string[] = documents?.map((c) => c.id!);
    newDocIds.splice(sourceIndex, 1);
    newDocIds.splice(destinationIndex, 0, id);
    onReorderDocuments(newDocIds);
  };

  const handleDragUpdate = (destinationIndex: number, id: string) => {
    if (!localPhotoList) return;
    const insertItem = [...localPhotoList].find((item) => item.id === id);
    if (!insertItem) return;
    const filteredPhotoList = localPhotoList.filter((item) => item.id !== id);
    filteredPhotoList.splice(destinationIndex, 0, insertItem);
    setLocalPhotoList(filteredPhotoList);
  };

  return (
    <Stack spacing={2}>
      <Box>
        <FileUploadButton onUpload={onAddDocument} accept={'image/*'} loading={loading}>
          Upload Photo
        </FileUploadButton>
      </Box>
      <DroppableComponent
        droppableId={'droppable-document-list'}
        onDragEnd={handleDragEnd}
        onDragUpdate={handleDragUpdate}
      >
        {localPhotoList.map((document, index) => (
          <ReorderableDocumentCard
            key={document.id}
            document={document}
            onRemoveDocument={onRemoveDocument}
            index={index}
            loading={loading}
            listingId={listingId}
          />
        ))}
      </DroppableComponent>
    </Stack>
  );
};
