import React, { FC, useEffect, useRef, useState } from 'react';
import { StripedDataGrid } from '../../../../../_shared/datagrids/StripedDataGrid';
import { IContactPerson } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import {
  DataGridPremiumProps,
  GridColDef,
  GridRenderEditCellParams,
  GridRowModes,
  GridRowModesModel,
  GridRowsProp,
  GridToolbarContainer,
  GridToolbarProps,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { PhoneTextField } from '../../../../../_shared/texfields/PhoneTextField';
import { useIcons } from '../../../../../_shared/icons/useIcons';

type ContactWithId = IContactPerson & { id: number };

type ContactsTableProps = {
  loading?: boolean;
  value: IContactPerson[];
  onSave: (values: IContactPerson[]) => void;
};

function EditToolbar(props: GridToolbarProps) {
  const { gridRef, nextId, setRows, setEditRowsModel } = props;

  const handleClick = () => {
    setRows((oldRows: GridRowsProp<ContactWithId>) => [
      ...oldRows,
      {
        id: nextId,
      },
    ]);
    setEditRowsModel((oldModel: GridRowModesModel) => {
      return {
        ...oldModel,
        [nextId]: {
          mode: GridRowModes.Edit,
        },
      };
    });
    setTimeout(() => {
      setTimeout(() => {
        const field = 'firstName';
        gridRef?.current?.apiRef?.current.setCellFocus(nextId, field);
        // Optionally select the text
        const cellInput = document.querySelector<HTMLInputElement>(
          `[data-id='${nextId}'] [data-field='${field}'] input`,
        );
        cellInput?.select();
      }, 100);
    }, 100);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" variant={'outlined'} startIcon={<AddIcon />} onClick={handleClick}>
        Add contact
      </Button>
    </GridToolbarContainer>
  );
}

export const PhoneNumberCell: FC<GridRenderEditCellParams> = (props) => {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (hasFocus) {
      ref.current?.focus();
    }
  }, [hasFocus]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <PhoneTextField
      ref={ref}
      InputProps={{
        disableUnderline: true,
      }}
      includeHelperText={false}
      variant="standard"
      type="text"
      value={value}
      onChange={handleValueChange}
    />
  );
};

export const ContactsTable: FC<ContactsTableProps> = ({ loading, value, onSave }) => {
  const { getActionIcon, ActionType } = useIcons();
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [rows, setRows] = React.useState<ContactWithId[]>(value.map((x, i) => ({ ...x, id: i })));
  const gridRef = useRef<HTMLDivElement & DataGridPremiumProps>(null);
  useEffect(() => {
    setRows(value.map((x, i) => ({ ...x, id: i })));
  }, [value]);
  const columns: GridColDef<IContactPerson>[] = [
    { field: 'firstName', headerName: 'First Name', flex: 1, editable: true },
    { field: 'lastName', headerName: 'Last Name', flex: 1, editable: true },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      editable: true,
      valueSetter: (params) => {
        return { ...params.row, contactInfo: { ...params.row.contactInfo, email: params.value } };
      },
      valueGetter: (value, row) => row.contactInfo?.email ?? '',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      editable: true,
      renderEditCell: (params) => <PhoneNumberCell {...params} />,
      renderCell: (params) => {
        return params.value ? (
          <a href={`tel:${params.value}`} style={{ textDecoration: 'none' }}>
            {params.value}
          </a>
        ) : (
          ''
        );
      },
      valueSetter: (params) => {
        return { ...params.row, contactInfo: { ...params.row.contactInfo, phone: params.value } };
      },
      valueGetter: (value, row) => row.contactInfo?.phone ?? '',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <Stack direction={'row'} spacing={1}>
          <Tooltip title={'Delete'}>
            <IconButton size={'small'} onClick={() => onSave(rows.filter((r) => r.id !== params.id))}>
              {getActionIcon(ActionType.Delete)}
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  const handleSave = (updatedRow: GridValidRowModel) => {
    const updatedRows = rows.map((row) => (row.id === updatedRow?.id ? updatedRow : row));
    onSave(updatedRows);
    return updatedRow;
  };

  return (
    <StripedDataGrid
      disableRowGrouping
      autoHeight
      ref={gridRef}
      columns={columns}
      rows={rows}
      loading={loading}
      editMode={'row'}
      rowModesModel={rowModesModel}
      onRowModesModelChange={(model) => setRowModesModel(model)}
      processRowUpdate={handleSave}
      onCellEditStop={(params) => onSave(params.value)}
      slots={{
        toolbar: EditToolbar,
      }}
      slotProps={{
        toolbar: {
          gridRef,
          nextId: Math.max(...rows.map((r) => r.id)) + 1,
          setRows,
          setRowModesModel,
        },
      }}
    />
  );
};
