import React, { FC, FormEvent, useEffect, useState } from 'react';
import { useIcons } from '../../icons/useIcons';
import { DrawerLayout, DrawerNavItem } from '../../../global/layout/DrawerLayout';
import { EmailTemplateTab } from '../../tabs/TabTypeEnums';
import { AssociationType } from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { useRouting } from '../../utils/useRouting';
import { useNavigate, useParams } from 'react-router-dom';
import { getAssetTypeFromPathname } from '../../utils/getAssetTypeFromPathname';
import Box from '@mui/material/Box';
import { FullForm } from '../../styledComponents/FullForm';
import {
  addDocumentToEmailTemplateAction,
  removeDocumentFromEmailTemplateAction,
  updateEmailTemplateAction,
  useEmailTemplates,
} from '../redux/emailTemplateSlice';
import { EmailTemplateDialogMode, EmailTemplateInfoForm } from './EmailTemplateInfoForm';
import { useDispatch } from 'react-redux';
import { validateHandlebarKeys } from '../redux/emailTemplateData';
import { showToastMessageAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/global/globalSlice';
import { StyledInfoBox } from '../../styledComponents/StyledInfoBox';
import { DocumentList } from '../../documents/components/DocumentList';
import { ReduxEmailTemplate } from '@monkeyjump-labs/cam-fe-shared/dist/types/emailTemplateTypes';

export const EmailTemplateDrawer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { emailTemplates, allowedKeys } = useEmailTemplates();
  const { handleNavigateFromAssociationClose } = useRouting();
  const { id, outerTab, innerTab, emailTemplateId, tab } = useParams();

  const [editingEmailTemplate, setEditingEmailTemplate] = useState<ReduxEmailTemplate | undefined>(undefined);
  const [drawerName, setDrawerName] = useState<string>('Email Template');
  const [dirty, setDirty] = useState<boolean>(false);

  const { getTabIcon } = useIcons();

  useEffect(() => {
    const template = emailTemplates.value?.find((t) => t.id === emailTemplateId);

    if (template) {
      setEditingEmailTemplate(template);
      setDrawerName(template.name ?? 'Email Template');
    }
  }, [emailTemplateId, emailTemplates.value]);

  const tabs: DrawerNavItem[] = [
    {
      icon: getTabIcon('info'),
      label: 'Info',
      value: EmailTemplateTab.Info,
    },
    {
      icon: getTabIcon('document'),
      label: 'Documents',
      value: EmailTemplateTab.Documents,
    },
  ];

  const handleCloseDrawer = () => {
    setEditingEmailTemplate(undefined);
    handleNavigateFromAssociationClose();
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!editingEmailTemplate?.type) return;
    if (
      !validateHandlebarKeys(editingEmailTemplate.type, allowedKeys.value, editingEmailTemplate?.body) ||
      !validateHandlebarKeys(editingEmailTemplate.type, allowedKeys.value, editingEmailTemplate?.subject)
    ) {
      dispatch(showToastMessageAction({ message: 'Invalid keys in email template', severity: 'error' }));
      return;
    }
    editingEmailTemplate &&
      editingEmailTemplate.id &&
      dispatch(updateEmailTemplateAction({ id: editingEmailTemplate.id, emailTemplate: editingEmailTemplate }));
    setDirty(false);
  };

  const handleDocumentUpload = (file: File) => {
    if (editingEmailTemplate?.id) {
      dispatch(
        addDocumentToEmailTemplateAction({
          id: editingEmailTemplate.id,
          file,
        }),
      );
    }
  };

  const handleRemoveDocument = (documentId: string) => {
    if (!editingEmailTemplate?.id) return;

    dispatch(removeDocumentFromEmailTemplateAction({ id: editingEmailTemplate.id, documentId }));
  };

  return (
    <>
      {editingEmailTemplate && (
        <>
          <DrawerLayout
            title={drawerName}
            open={!!editingEmailTemplate}
            onCloseDrawer={handleCloseDrawer}
            tabs={tabs}
            tabValue={tab}
            onTabChange={(newValue: string) => {
              navigate(
                `/assets/${getAssetTypeFromPathname()}/${id}/${outerTab}/${innerTab}/emailTemplate/${emailTemplateId}/${newValue}`,
              );
            }}
          >
            <Box sx={{ width: '100%', m: 1 }}>
              {
                {
                  info: (
                    <FullForm onSubmit={onSubmit}>
                      <EmailTemplateInfoForm
                        mode={EmailTemplateDialogMode.Edit}
                        editingTemplate={editingEmailTemplate}
                        onEditTemplate={(updatedTemplate) => {
                          setEditingEmailTemplate(updatedTemplate);
                          setDirty(true);
                        }}
                        dirty={dirty}
                      />
                    </FullForm>
                  ),
                  documents: (
                    <StyledInfoBox label={'Documents'}>
                      <DocumentList
                        propertyId={id}
                        association={{
                          associatedId: editingEmailTemplate?.id,
                          associationType: AssociationType.EmailTemplate,
                        }}
                        value={editingEmailTemplate?.attachments ?? []}
                        onUpload={handleDocumentUpload}
                        uploading={emailTemplates.submitting}
                        onDeleteDocument={handleRemoveDocument}
                      />
                    </StyledInfoBox>
                  ),
                }[tab ?? 'info']
              }
            </Box>
          </DrawerLayout>
        </>
      )}
    </>
  );
};
