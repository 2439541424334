import React, { FC, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { TaskCategoryAutocomplete } from './TaskCategoryAutocomplete';
import { AssigneeAutocomplete, UserOption } from './AssigneeAutocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  AssetType,
  ITaskCategory,
  TaskType,
} from '@monkeyjump-labs/cam-fe-shared/dist/services/generated/ApiClientGenerated';
import { getAllCategoriesAction } from '@monkeyjump-labs/cam-fe-shared/dist/redux/tasks/categorySlice';
import { getUsersForAssetAction, useUser } from '@monkeyjump-labs/cam-fe-shared/dist/redux/user/userSlice';
import { useDispatch } from 'react-redux';
import { ReduxTask } from '@monkeyjump-labs/cam-fe-shared/dist/types/taskTypes';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { DraggableComponent } from '../../dragAndDrop/DraggableComponent';
import { DroppableComponent } from '../../dragAndDrop/DroppableComponent';

type AddTaskFormProps = {
  onChange: (value: ReduxTask) => void;
  value?: ReduxTask;
  propertyId?: string;
};

export const AddTaskForm: FC<AddTaskFormProps> = ({ onChange, value, propertyId }) => {
  const dispatch = useDispatch();
  const usersForAsset = useUser((u) => u.usersForAsset.value);
  const handleTaskType = (e: SelectChangeEvent) => {
    e.target.value === 'Normal'
      ? onChange({ ...value, taskType: TaskType.Normal })
      : e.target.value === 'Safety'
        ? onChange({ ...value, taskType: TaskType.Normal })
        : onChange({ ...value, taskType: TaskType.Normal });
  };

  const handleCategorySelection = (v: ITaskCategory | null) => {
    onChange({ ...value, category: v ?? undefined });
  };

  const handleAssigneeChange = (user: UserOption | null | undefined) => {
    user === null || (user === undefined && onChange({ ...value, assignee: undefined }));
    user &&
      usersForAsset?.map((user) => {
        user.id === user?.id && onChange({ ...value, assignee: { ...user } });
      });
  };
  const handleDragUpdate = () => {};
  const handleDragEnd = (sourceIndex: number, destinationIndex: number) => {
    const newChecklist = value?.checklist ?? [];
    const [removed] = newChecklist.splice(sourceIndex, 1);
    newChecklist.splice(destinationIndex, 0, removed);
    onChange({ ...value, checklist: newChecklist });
  };

  useEffect(() => {
    if (!propertyId) return;
    dispatch(getAllCategoriesAction({ propertyId: propertyId, includeDisabled: false }));
    dispatch(
      getUsersForAssetAction({
        assetId: propertyId,
        associationType: AssetType.RentalProperty,
      }),
    );
  }, [propertyId]);

  return (
    <Stack spacing={2}>
      <TextField
        autoFocus
        fullWidth
        required
        variant={'outlined'}
        label={'Task Name'}
        onChange={(e) => onChange({ ...value, name: e.target.value })}
        value={value?.name ?? ''}
      />
      <TextField
        fullWidth
        label={'Description'}
        variant={'outlined'}
        multiline
        minRows={2}
        onChange={(e) => onChange({ ...value, description: e.target.value })}
        value={value?.description ?? ''}
      />
      <TaskCategoryAutocomplete onChange={handleCategorySelection} value={value?.category} />
      <AssigneeAutocomplete onValueChange={handleAssigneeChange} value={value?.assignee ?? undefined} />
      <FormControl fullWidth>
        <InputLabel id="task-type">Task Type</InputLabel>
        <Select
          labelId="task-type-label"
          id="task-type"
          label="Task Type"
          value={value?.taskType ? value.taskType : ''}
          onChange={(e) => {
            handleTaskType(e);
          }}
        >
          <MenuItem value={'Normal'}>Normal</MenuItem>
          <MenuItem value={'Safety'}>Safety</MenuItem>
          <MenuItem value={'Urgent'}>Urgent</MenuItem>
        </Select>
      </FormControl>
      {value?.checklist?.length && (
        <Box>
          <Typography variant={'h6'} color="primary">
            Checklist
          </Typography>
          <Divider sx={{ marginTop: 0, mt: 0 }} />
        </Box>
      )}
      {value?.checklist?.length && (
        <DroppableComponent
          droppableId={'droppable-checklist'}
          onDragUpdate={handleDragUpdate}
          onDragEnd={handleDragEnd}
        >
          {value?.checklist?.map((item, index) => (
            <DraggableComponent
              key={item.name!}
              id={item.name!}
              index={index}
              elevation={1}
              sx={{ p: 1, mt: 1, borderRadius: '2px', cursor: 'grab' }}
            >
              <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between', p: 0 }}>
                <Box sx={{ display: 'flex' }}>
                  <DragHandleIcon />
                  <Typography sx={{ ml: 1 }}>{item.name}</Typography>
                </Box>
                <CloseIcon
                  onClick={() => {
                    onChange({
                      ...value,
                      checklist: value.checklist?.filter((i) => i.id !== item.id),
                    });
                  }}
                  sx={{ cursor: 'pointer' }}
                  color={'action'}
                />
              </Box>
            </DraggableComponent>
          ))}
        </DroppableComponent>
      )}
    </Stack>
  );
};
